import { UsuarioChangePasswordRequest } from 'src/model/request/user.models'
import { DashboardWidget, DashboardType } from 'src/model/response/user.models'
import { api } from '../boot/axios'
export const UserService = {
  async changePassword(request: UsuarioChangePasswordRequest): Promise<void> {
    return await api.post('/user/changePassword', request)
  },
  async getDashboardWidgets(dashboard: DashboardType): Promise<DashboardWidget[]> {
    return (await api.get(`/user/dashboard/${dashboard}/widgets`)).data
  },
  async saveDashboardWidgets(widgets: DashboardWidget[], dashboard: DashboardType): Promise<DashboardWidget[]> {
    return await api.post(`/user/dashboard/${dashboard}/widgets`, widgets)
  },
}
