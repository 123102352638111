import { computed } from 'vue'

export function useModelWrapper<T>(
  props: { [x: string]: unknown },
  emit: (e: string, args: T) => void,
  name = 'modelValue'
) {
  return computed({
    get: () => props[name],
    set: (value) => {
      emit(`update:${name}`, value as T)
    },
  })
}
