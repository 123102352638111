<script setup lang="ts">
import DWidget from 'src/components/DWidget.vue'
import { useWeather } from 'src/composables/weather'
//import ATooltip from 'src/components/ATooltip.vue'
const { weather, loading } = useWeather()

console.log(weather.value)
</script>

<template>
  <d-widget
    v-if="weather"
    :loading="loading"
    justify-header="left"
  >
    <!-- <template v-slot:header>
      <d-icon name="thermometer" />
    </template> -->
    <template v-slot:body>
      <div>
        <d-icon name="device_thermostat" />
      </div>
      <div
        v-if="!loading"
        class="body"
      >
        <div class="flex items-center justify-center">
          <img
            :src="`https://www.aemet.es/imagenes/png/estado_cielo/${weather.sky.value}.png`"
            v-if="weather.sky"
          />
          <span class="d-display-md-semibold text-blue900">{{ weather.temp }}º</span>
        </div>
        <div class="d-text-xs-regular text-grey900">Máx. {{ weather.max }}º Mín. {{ weather.min }}º</div>
      </div>
    </template>
  </d-widget>
</template>

<style scoped lang="scss">
.d-widget {
  .d-icon {
    font-size: var(--icon-size-l);
    color: var(--grey-400);
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .d-widget-body {
    display: flex;
    .body {
      color: var(--blue-900);
      margin: auto;
    }

    img {
      height: 40px;
    }
  }
}
</style>
