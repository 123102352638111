<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useKPIs } from 'src/composables/kpis'
import DWidget from './DWidget.vue'
import { fromYearMonth, formatNumber, ChartEventArgs } from 'driving01-charts'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es')

const props = withDefaults(defineProps<{ kpi: string; height?: number; width?: number; showTooltip?: boolean; gridCurrentColumns?: number }>(), { showTooltip: true })
console.log(`kpi ${props.kpi}`)
console.log(`height ${props.height}, width:${props.width}`)
console.log(`gridCurrentColumns ${props.gridCurrentColumns}`)
const { counterKpi } = useKPIs('year', -1)
const { counterKpi: counterKpiGrafico, kpisLoading } = useKPIs('year', -2)

const kpiData = computed(() => counterKpi.value(props.kpi))

const kpiDataGraficoModoAño = computed(() => kpiData.value?.modoAño ?? false)

const kpiDataGrafico = computed(() => {
  return counterKpiGrafico.value(kpiData.value?.id ?? props.kpi, kpiDataGraficoModoAño.value ? 'year' : undefined, kpiDataGraficoModoAño.value ? -5 : undefined)
})

const isOneColumn = computed(() => props.gridCurrentColumns == 1)

const data = computed(() => {
  if (kpiDataGrafico.value != null) {
    return {
      values: [kpiDataGrafico.value.valores, kpiDataGrafico.value.trend],
      category: [(value: any) => fromYearMonth(value.pkAnyomes), (value: any) => new Date(value.x)],
      value: [(value: any) => value.valor, (value: any) => value.y],
      type: ['area', 'line'],
      color: [kpiDataGrafico.value?.trendColor + '|gradient', 'var(--grey-400)|dash'],
      adjustValueDomain: true,
      datesGroupedBy: (props.height ?? 0) > 1 ? (kpiDataGraficoModoAño.value || (props.width ?? 0) == 1 ? 'year' : 'quarter') : null,
    }
  } else return {}
})

watch(
  () => props.width,
  (nuevoValor, valorAntiguo) => {
    console.log(`La propiedad width ha cambiado de ${valorAntiguo} a ${nuevoValor}`)
    console.log(`Altura  ${props.height}`)
    // Puedes realizar acciones adicionales aquí
  },
)

watch(
  () => props.gridCurrentColumns,
  (nuevoValor, valorAntiguo) => {
    console.log(`La propiedad gridCurrentColumns ha cambiado de ${valorAntiguo} a ${nuevoValor}`)
    // Puedes realizar acciones adicionales aquí
  },
)

const tooltip = ref()
const onTooltip = (e: ChartEventArgs) => (tooltip.value = e)
</script>

<template>
  <div class="d-kpi-graph-widget">
    <d-widget
      justify-header="space-between"
      :class="{ 'with-summary': height == 1 && width == 2 && !isOneColumn }"
      class="no-border no-border-radius"
      :loading="kpisLoading"
      body-class="no-margin no-padding"
    >
      <template
        v-slot:header
        v-if="kpiData"
      >
        <div>
          <div class="row">
            <div class="col">
              <d-icon name="fmd_bad" />
              <span class="d-text-xs-semibold text-blue600">{{ kpiData.id?.match(/[a-zA-Z]+/)?.at(0) + (kpiData.id.includes('OPERATIVO') ? ' ' + $t('Administrador') : '') }} </span>
            </div>
          </div>
          <div class="row d-text-sm-medium text-grey500">{{ kpiData.indicador }}</div>
        </div>
        <div v-if="!(height == 1 && width == 2 && !isOneColumn)">
          <span
            class="d-display-xs-semibold"
            :class="`${kpiData.valor ?? 0 <= (kpiData.contraValor ?? 0) ? 'text-' + kpiData.color + '400' : 'text-' + kpiData.color + '400'}`"
            >{{
              formatNumber(kpiData.valor ?? 0, {
                formatOptions: {
                  useGrouping: true,
                  notation: (kpiData.valor ?? 0) > 99999 ? 'compact' : 'standard',
                },
              })
            }}</span
          >
          <span
            v-if="width == 2 && !isOneColumn"
            class="d-display-xs-semibold text-grey500"
          >
            /
            {{
              formatNumber(kpiData.contraValor ?? 0, {
                formatOptions: {
                  useGrouping: true,
                  notation: (kpiData.contraValor ?? 0) > 99999 ? 'compact' : 'standard',
                },
              })
            }}</span
          >
        </div>
      </template>
      <template
        v-slot:body
        v-if="kpiDataGrafico"
      >
        <div class="d-widget-body-graph q-pt-md">
          <d-grid-chart
            :data="data.values"
            :category="data.category"
            :value="data.value"
            :type="data.type"
            :color="data.color"
            :adjust-value-domain="data.adjustValueDomain"
            :dates-grouped-by="data.datesGroupedBy"
            hidden-inner-v-lines
            :hidden-inner-lines="height == 1"
            :hidden-outer-lines="height == 1"
            :full-content="height == 1"
            @tooltip="onTooltip"
            padding-top-bottom="0"
            :zero-line="false"
            :border="false"
            :value-format="
              (v: number) =>
                formatNumber(v, {
                  formatOptions: {
                    useGrouping: false,
                  },
                })
            "
          >
            <!-- :value-format="(v: number) => formatNumber(v)" -->
            <template
              v-slot:tooltip
              v-if="showTooltip"
            >
              <template v-if="tooltip">
                <div>{{ dayjs(tooltip.data.category).format('MMMM YYYY') }}</div>
                <div>
                  {{ $t('Valor') }}:
                  {{
                    formatNumber(tooltip.data.value[0] ?? 0, {
                      formatOptions: {
                        useGrouping: true,
                        notation: (tooltip.data.value[0] ?? 0) > 99999 ? 'compact' : 'standard',
                      },
                    })
                  }}
                </div>
              </template>
            </template>
          </d-grid-chart>
        </div>
      </template>
    </d-widget>
    <div
      v-if="kpiData && height == 1 && width == 2 && !isOneColumn"
      class="d-widget-body-summary"
    >
      <div
        class="d-display-md-semibold q-mb-sm"
        :class="`${kpiData.valor ?? 0 <= (kpiData.contraValor ?? 0) ? 'text-' + kpiData.color + '400' : 'text-' + kpiData.color + '400'}`"
      >
        {{
          formatNumber(kpiData.valor ?? 0, {
            formatOptions: {
              useGrouping: true,
              notation: kpiData.valor ?? 0 > 99999 ? 'compact' : 'standard',
            },
          })
        }}
      </div>
      <div class="d-text-sm-regular text-grey500">
        {{ $t('UltimoAño') }}
        <span class="d-text-sm-semibold">{{
          formatNumber(kpiData.contraValor ?? 0, {
            formatOptions: {
              useGrouping: true,
              notation: kpiData.contraValor ?? 0 > 99999 ? 'compact' : 'standard',
            },
          })
        }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.d-kpi-graph-widget {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .d-widget-body-summary {
    position: relative;
    float: right;
    width: 194px;
    height: 100%;
    border-left: 1px solid var(--grey-200);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .d-widget {
    position: relative;
    float: left;
    width: 100%;

    &.with-summary {
      width: calc(100% - 194px);
    }

    .d-widget-header {
      color: var(--grey-500);

      .d-icon {
        font-size: var(--icon-size-l);
        color: var(--grey-400);
      }
    }

    .d-widget-body {
      .d-widget-body-graph {
        width: 100%;
        height: 100%;
      }
    }

    > div:first-child {
      height: 100%;
    }
  }
}
</style>
