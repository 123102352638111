<script setup lang="ts">
import DWidget from 'src/components/DWidget.vue'
import { useKPIs } from 'src/composables/kpis'

const { counterKpis, kpisLoading } = useKPIs('month', -1)
</script>

<template>
  <d-widget
    border-header
    body-align="start"
    :loading="kpisLoading"
  >
    <template v-slot:header>
      <div class="header">
        <d-icon name="swap_vertical_circle" />
        <span class="d-text-xl-semibold text-grey800">{{ $t('CambiosTendencia') }}</span>
      </div>
    </template>
    <template v-slot:body>
      <div class="body">
        <template
          v-for="kpi in counterKpis"
          :key="kpi.id"
        >
          <div
            class="d-text-sm-semibold"
            v-if="kpi.valor / kpi.contraValor || kpi.pkAnyomes === 0"
          >
            <d-featured-icon
              icon="stacked_line_chart"
              size="md"
              borderless
              light
              :color="kpi.color"
            />
            <div class="indicador text-grey600">
              <div>{{ kpi.modulo }} + {{ kpi.indicador }} {{ kpi.porcentajeDiferencia < 0 ? $t('baja') : kpi.porcentajeDiferencia > 0 ? $t('sube') : $t('seHaEstabilizado') }}</div>
              <div
                v-if="kpi.porcentajeDiferencia !== 0"
                class="d-text-sm-regular"
              >
                {{ kpi.porcentajeDiferencia }}%
                <!-- {{ (10000 - Math.round((kpi.valor / kpi.contraValor) * 10000)) / 100 }}% -->
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </d-widget>
</template>

<style scoped lang="scss">
.header {
  padding: 8px 0 16px 0;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 8px;
  }
}

.body {
  text-align: left;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &:first-of-type {
      margin-top: 8px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    & > .indicador {
      width: calc(100% - 64px);
      margin-left: 16px;
    }
  }
}

.d-widget {
  .d-widget-header {
    .d-icon {
      font-size: var(--icon-size-l);
      color: var(--grey-400);
    }
  }

  .d-widget-body {
    align-items: normal !important;
  }

  .d-widget-body > div {
    margin-top: 5px;
  }
}
</style>
