import dayjs from 'dayjs'
//import { GeolocationResponse, TownResponse, TownsResponse } from 'src/model/response/weather.models'
import { weatherService } from 'src/services/weatherService'
import { ref, computed, watch } from 'vue'

const municipio = ref<string | null>(null)
const loadingLocation = ref(false)
const loadingTowns = ref(false)
const loadingWeather = ref(false)

export function useWeather() {
  const weather = ref<Partial<{ max: number; min: number; temp: string; town?: string; sky: { value: string; descripcion: string } }>>({})

  const refreshWeather = async (town: string | null | undefined) => {
    if (town === null || town === undefined) return null
    loadingWeather.value = true

    console.log('refreshWeather - town', town)
    const value = await weatherService.getDiaryWeather(town ?? '')

    const dia = value[0].prediccion.dia.find((d: { fecha: Date }) => {
      return dayjs().isSame(d.fecha, 'date')
    })
    if (dia != null) {
      const temperatura = dia.temperatura.find((d: { periodo: string }) => +d.periodo === new Date().getHours()) ?? dia.temperatura[0]

      const min = dia.temperatura.reduce((a: number, b: { value: string }) => (a < +b.value ? a : +b.value), 99)
      const max = dia.temperatura.reduce((a: number, b: { value: string }) => (a > +b.value ? a : +b.value), -99)

      const sky = dia.estadoCielo.find((d: { periodo: string }) => +d.periodo === new Date().getHours()) ?? dia.estadoCielo[0]

      weather.value = { max, min, temp: temperatura.value, sky }
    }
    loadingWeather.value = false
  }

  const loading = computed(() => loadingWeather.value || loadingTowns.value || loadingLocation.value)

  if (municipio.value === null && !loadingLocation.value) {
    loadingLocation.value = true

    weatherService.getMunicipio().then((value: string) => {
      municipio.value = value
      loadingLocation.value = false
    })
  }
  if (municipio.value != null) {
    refreshWeather(municipio.value)
  }

  watch(municipio, async (newTown) => {
    await refreshWeather(newTown)
  })

  return { weather, loading }
}
