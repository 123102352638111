import { Rol } from 'src/store/authStore'
import { RouteRecordRaw } from 'vue-router'
import { useI18n } from 'src/boot/i18n'
import { keys as bm } from '../pages/BM/translations/keys'
import { keys as calculadora } from '../pages/Calculadoras/translations/keys'

const { t } = useI18n()

const routes: RouteRecordRaw[] = [
  {
    path: '/logout',
    name: 'logout',
    component: () => import('pages/auth/logout.vue'),
  },
  {
    path: '/',
    component: () => import('layouts/data/layout.vue'),
    children: [
      {
        name: 'DashboardData',
        path: '',
        component: () => import('pages/Dashboard/dashboard.vue'),
        props: () => {
          return {
            dashboard: 'data',
          }
        },
        meta: { authorize: [Rol?.TAAFDataDashBoard ?? '1-0'] },
      },
      // {
      //   name: 'DashboardPortal',
      //   path: '/portal',
      //   component: () => import('pages/Dashboard/dashboard.vue'),
      //   props: () => {
      //     return {
      //       dashboard: 'portal',
      //     }
      //   },
      // },
      // {
      //   name: 'DashboardGlobal',
      //   path: '/global',
      //   component: () => import('pages/Dashboard/dashboard.vue'),
      //   props: () => {
      //     return {
      //       dashboard: 'global',
      //     }
      //   },
      // },
      // {
      //   name: 'DashboardData',
      //   path: '',
      //   component: () => import('pages/PowerBI/index.vue'),
      // },
      {
        name: 'DataReport',
        path: '/data/:idAplicacion/:idModulo/:idObjeto',
        component: () => import('pages/PowerBI/index.vue'),
        props: (route) => {
          return {
            ...route.params,
            ...{
              idAplicacion: Number.parseInt(route.params.idAplicacion as string, 10) || undefined,
              idModulo: Number.parseInt(route.params.idModulo as string, 10) || undefined,
              idObjeto: Number.parseInt(route.params.idObjeto as string, 10) || undefined,
              personalizado: false,
            },
          }
        },
      },
      {
        name: 'DataReportPersonalizado',
        path: '/data/personalizado/:idAplicacion/:idModulo/:idObjeto',
        component: () => import('pages/PowerBI/index.vue'),
        props: (route) => {
          return {
            ...route.params,
            ...{
              idAplicacion: Number.parseInt(route.params.idAplicacion as string, 10) || undefined,
              idModulo: Number.parseInt(route.params.idModulo as string, 10) || undefined,
              idObjeto: Number.parseInt(route.params.idObjeto as string, 10) || undefined,
              personalizado: true,
            },
          }
        },
      },
      {
        name: 'Perfil',
        path: '/perfil',
        component: () => import('pages/Usuario/Perfil.vue'),
        meta: { title: t('Perfil') },
      },
    ],
    meta: { authorize: [Rol?.TAAFData ?? 1] },
  },
  {
    path: '/',
    component: () => import('layouts/loginLayout.vue'),
    children: [
      { path: 'login', name: 'login', component: () => import('pages/auth/login.vue'), meta: { title: t('InicioSesion') } },
      { path: 'login/:tokenUrl', name: 'loginTokenUrl', component: () => import('pages/auth/loginTokenUrl.vue'), meta: { title: t('InicioSesion') } },
      { path: 'reset-password', name: 'resetPassword', component: () => import('pages/auth/ResetPassword.vue'), meta: { title: t('RecuperarContraseña') } },
      { path: 'reset-password/:token', component: () => import('pages/auth/ResetPasswordChange.vue'), meta: { title: t('RestablecerContraseña') } },
    ],
    meta: { onlyAnonymous: true },
  },
  /*{
    path: '/',
    component: () => import('layouts/main.vue'),
    children: [
      { path: '/ph/dashboard-general', component: () => import('pages/PH/dashboard-general.vue'), meta: { title: t('PHDashboardGeneral') } },
      { path: '/ph/facturas-clientes-por-departamento', component: () => import('pages/PH/facturas-clientes-por-departamento.vue'), meta: { title: t('PHFacturasClientesPorDepartamento') } },
      { path: '/ph/facturas-clientes-por-concepto', component: () => import('pages/PH/facturas-clientes-por-concepto.vue'), meta: { title: t('PHFacturasClientesPorConcepto') } },
      { path: '/ph/facturas-proveedores-por-concepto', component: () => import('pages/PH/facturas-proveedores-por-concepto.vue'), meta: { title: t('PHFacturasProveedoresPorConcepto') } },
      { path: '/ph/administradores', component: () => import('pages/PH/administradores.vue'), meta: { title: t('PHAdministradores') } },
      { path: '/ph/cartera', component: () => import('pages/PH/cartera.vue'), meta: { title: t('PHCartera') } },
      { path: '/ph/dashboard-administrador', component: () => import('pages/PH/dashboard-administrador.vue'), meta: { title: t('PHDashboardAdministrador') } },
      { path: '/ph/saldos', component: () => import('pages/PH/saldos.vue'), meta: { title: t('PHSaldos') } },
      { path: '/ph/morosidad', component: () => import('pages/PH/morosidad.vue'), meta: { title: t('PHMorosidad') } },
      { path: '/ph/juntas', component: () => import('pages/PH/juntas.vue'), meta: { title: t('PHJuntas') } },
      { path: '/ph/incidencias', component: () => import('pages/PH/incidencias.vue'), meta: { title: t('PHIncidencias') } },
      { path: '/ph/presupuestos', component: () => import('pages/PH/presupuestos.vue'), meta: { title: t('PHPresupuestos') } },
      { path: '/ph/kpis', component: () => import('pages/PH/kpis.vue'), meta: { title: 'KPIs' } },
    ],
    meta: { authorize: [Rol.TAAFData] },
  },
  {
    path: '/',
    component: () => import('layouts/main.vue'),
    children: [
      { path: '/pv/dashboard-general', component: () => import('pages/PV/dashboard-general.vue'), meta: { title: t('PVDashboardGeneral') } },
      { path: '/pv/facturas-clientes-por-departamento', component: () => import('pages/PV/facturas-clientes-por-departamento.vue'), meta: { title: t('PVFacturasClientesPorDepartamento') } },
      { path: '/pv/facturas-clientes-por-concepto', component: () => import('pages/PV/facturas-clientes-por-concepto.vue'), meta: { title: t('PVFacturasClientesPorConcepto') } },
      { path: '/pv/facturas-proveedores-por-concepto', component: () => import('pages/PV/facturas-proveedores-por-concepto.vue'), meta: { title: t('PVFacturasProveedoresPorConcepto') } },
      { path: '/pv/administradores', component: () => import('pages/PV/administradores.vue'), meta: { title: t('PVAdministradores') } },
      { path: '/pv/cartera', component: () => import('pages/PV/cartera.vue'), meta: { title: t('PVCartera') }  },
      { path: '/pv/dashboard-administrador', component: () => import('pages/PV/dashboard-administrador.vue'), meta: { title: t('PVAdministradores') } },
      { path: '/pv/saldos', component: () => import('pages/PV/saldos.vue'), meta: { title: t('PVSaldos') } },
      { path: '/pv/morosidad', component: () => import('pages/PV/morosidad.vue'), meta: { title: t('PVMorosidad') } },
      { path: '/pv/departamentos-con-contrato', component: () => import('pages/PV/departamentos-con-contrato.vue'), meta: { title: t('PVDepartamentosConContrato') } },
      { path: '/pv/departamentos-sin-contrato', component: () => import('pages/PV/departamentos-sin-contrato.vue'), meta: { title: t('PVDepartamentosSinContrato') } },
      { path: '/pv/incidencias', component: () => import('pages/PV/incidencias.vue'), meta: { title: t('PVIncidencias') } }
    ],
    meta: { authorize: [Rol.TAAFData] },
  },*/
  {
    path: '/',
    component: () => import('layouts/business/layout.vue'),
    children: [
      //PH
      { path: 'bm/ph/cartera', component: () => import('pages/BM/ph/cartera.vue'), meta: { title: t(bm.PH.Cartera.Titulo), authorize: [Rol.GlobalDataBenchmarkComunidades] } },
      { path: 'bm/ph/facturacion-total', component: () => import('pages/BM/ph/facturacion-total.vue'), meta: { title: t(bm.PH.FacturacionTotal.Titulo), authorize: [Rol.GlobalDataBenchmarkComunidades] } },
      { path: 'bm/ph/facturacion-cartera', component: () => import('pages/BM/ph/facturacion-cartera.vue'), meta: { title: t(bm.PH.FacturacionCartera.Titulo), authorize: [Rol.GlobalDataBenchmarkComunidades] } },
      { path: 'bm/ph/eficiencia', component: () => import('pages/BM/ph/eficiencia.vue'), meta: { title: t(bm.PH.Eficiencia.Titulo), authorize: [Rol.GlobalDataBenchmarkComunidades] } },
      { path: 'bm/ph/impagos', component: () => import('pages/BM/ph/impagos.vue'), meta: { title: t(bm.PH.Impagos.Titulo), authorize: [Rol.GlobalDataBenchmarkComunidades] } },

      //PV
      { path: 'bm/pv/cartera', component: () => import('pages/BM/pv/cartera.vue'), meta: { title: t(bm.PV.Cartera.Titulo), authorize: [Rol.GlobalDataBenchmarkFincas] } },
      { path: 'bm/pv/facturacion-total', component: () => import('pages/BM/pv/facturacion-total.vue'), meta: { title: t(bm.PV.FacturacionTotal.Titulo), authorize: [Rol.GlobalDataBenchmarkFincas] } },
      { path: 'bm/pv/facturacion-cartera', component: () => import('pages/BM/pv/facturacion-cartera.vue'), meta: { title: t(bm.PV.FacturacionCartera.Titulo), authorize: [Rol.GlobalDataBenchmarkFincas] } },
      { path: 'bm/pv/eficiencia', component: () => import('pages/BM/pv/eficiencia.vue'), meta: { title: t(bm.PV.Eficiencia.Titulo), authorize: [Rol.GlobalDataBenchmarkFincas] } },
      { path: 'bm/pv/impagos', component: () => import('pages/BM/pv/impagos.vue'), meta: { title: t(bm.PV.Impagos.Titulo), authorize: [Rol.GlobalDataBenchmarkFincas] } },

      //Global
      { path: 'bm/gen/desarrollo-negocio', component: () => import('pages/BM/glob/desarrollo-negocio.vue'), meta: { title: t(bm.GEN.DesarrolloNegocio.Titulo), authorize: [Rol.GlobalDataBenchmarkGlobal] } },
      { path: 'bm/gen/facturacion', component: () => import('pages/BM/glob/facturacion.vue'), meta: { title: t(bm.GEN.Facturacion.Titulo), authorize: [Rol.GlobalDataBenchmarkGlobal] } },
      { path: 'bm/gen/rentabilidad', component: () => import('pages/BM/glob/rentabilidad.vue'), meta: { title: t(bm.GEN.Rentabilidad.Titulo), authorize: [Rol.GlobalDataBenchmarkGlobal] } },
      { path: 'bm/gen/excelencia-operativa', component: () => import('pages/BM/glob/excelencia-operativa.vue'), meta: { title: t(bm.GEN.ExcelenciaOperativa.Titulo), authorize: [Rol.GlobalDataBenchmarkGlobal] } },
    ],
    meta: { unauthorizedPath: '/mi-zona' },
  },
  {
    path: '/',
    component: () => import('layouts/business/layout.vue'),
    children: [
      { path: 'calculadora/presupuesto', component: () => import('pages/Calculadoras/PresupuestoIncidencias/Index.vue'), meta: { title: t(calculadora.CabeceraCalculadoraPresupuestoIncidencias), authorize: [Rol.GlobalDataCalculadorasPresupuestosEIncidencias] } },
      { path: 'calculadora/immo', component: () => import('pages/Calculadoras/Immo/Index.vue'), meta: { title: t(calculadora.CabeceraCalculadoraImmo), authorize: [Rol.GlobalDataCalculadorasIMMO] } },
    ],
    meta: { unauthorizedPath: '/mi-zona' },
  },
  {
    path: '/',
    component: () => import('layouts/business/layout.vue'),
    children: [
      { path: 'mi-zona', component: () => import('pages/MiZona/Index.vue'), meta: { title: 'Mi Zona' } },
      { path: 'mi-zona/:ccaa', component: () => import('pages/MiZona/Detalle/Index.vue'), meta: { title: 'Mi Zona' }, props: true },
      { path: 'mi-zona/:ccaa/:provincia', component: () => import('pages/MiZona/Detalle/Index.vue'), meta: { title: 'Mi Zona' }, props: true },
      { path: 'mi-zona/:ccaa/:provincia:/:municipio', component: () => import('pages/MiZona/Detalle/Index.vue'), meta: { title: 'Mi Zona' }, props: true },
      { path: 'mi-zona/:ccaa/:provincia:/:municipio/:cp', component: () => import('pages/MiZona/Detalle/Index.vue'), meta: { title: 'Mi Zona' }, props: true },
      { path: 'mi-zona/perfil', component: () => import('pages/Usuario/Perfil.vue'), meta: { title: t('Perfil') } },
    ],
    meta: { authorize: [Rol.GlobalData] },
  },

  {
    name: 'PerfilBusiness',
    path: '/mi-zona/perfil',
    component: () => import('pages/Usuario/Perfil.vue'),
    meta: { title: t('Perfil'), authorize: [Rol.GlobalData] },
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
]

export default routes
