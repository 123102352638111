import { Rol, useAuthStore } from 'src/store/authStore'
import { route } from 'quasar/wrappers'
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory, Router } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

import routes from './routes'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
let routerInstance: Router
export default route(function (/* { store, ssrContext } */) {
  if (routerInstance == null) {
    const createHistory = process.env.SERVER ? createMemoryHistory : process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory

    routerInstance = createRouter({
      scrollBehavior: () => ({ left: 0, top: 0 }),
      routes,

      // Leave this as is and make changes in quasar.conf.js instead!
      // quasar.conf.js -> build -> vueRouterMode
      // quasar.conf.js -> build -> publicPath
      history: createHistory(process.env.VUE_ROUTER_BASE),
    })
  }

  trackRouter(routerInstance)

  routerInstance.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    console.log('Router.beforeEach')
    console.log(to)

    if (authStore.isAuthenticated) {
      if (authStore.isTokenExpired()) {
        if (!authStore.isGlobalExpired()) {
          try {
            console.log('refreshToken')
            await authStore.refreshToken()
          } catch {
            //Si falla al renovar ya redireccionará donde toque a continuación
          }
        } else authStore.clearSessionData()
      }
    }

    if (to.matched.some((record) => record.meta.authorize)) {
      if (!authStore.isAuthenticated) {
        console.log('Not authenticated')
        next({ name: 'login', query: to.fullPath == '/' ? undefined : { next: to.fullPath } })
      } else {
        let authorized = false

        //Se mira si tiene authroización observando los permisos de forma jerarquica.
        console.log(to.matched)
        to.matched
          .slice()
          .reverse()
          .some((route) => {
            if (route.meta.authorize) {
              const roles = route.meta.authorize as Rol[]
              authorized = roles.some((rol) => authStore.isAuthorized(rol))
              return true // Rompe el bucle al encontrar una ruta autorizada
            }
          })
        console.log('authorized', authorized)

        if (authorized) {
          //Autorizado
          next()
        } else {
          //No Autorizado
          //TODO: De momento lo mandamos al login, no es correcto, pero es que al princio la home que será TAAFData y requerirá el rol de TAAFData y General....
          //Quizas deberiamos tener una pagina de no autorizado, el pero esto será creo cuando tengamos las 3 partes MyTAAF, TAAF y Market.

          const unauthorizedPath = to.matched.map((route) => route.meta.unauthorizedPath).find((path) => path !== undefined)
          if (unauthorizedPath != null) next(unauthorizedPath)
          else {
            if (to.fullPath == '/') {
              if (authStore.isAuthorized(Rol.TAAFData) && !authStore.isAuthorized(Rol.TAAFDataDashBoard)) {
                if (authStore.usuario?.objetosPersonalizados != null && authStore.usuario?.objetosPersonalizados?.length > 0) {
                  const newRoute = `/data/personalizado/${authStore.usuario?.objetosPersonalizados[0].idAplicacion}/${authStore.usuario?.objetosPersonalizados[0].idModulo}/${authStore.usuario?.objetosPersonalizados[0].idObjeto}`
                  next(newRoute)
                }
              } else if (!authStore.isAuthorized(Rol.TAAFData) && authStore.isAuthorized(Rol.GlobalData)) next('/mi-zona')
              else if (!authStore.isAuthorized(Rol.GlobalData)) next('/login')
            } else next('/')
          }
        }
      }
    } else {
      if (to.matched.some((record) => record.meta.onlyAnonymous)) {
        if (authStore.isAuthenticated) next('/')
        else next()
      } else next()
    }
  })

  return routerInstance
})

export { routerInstance }
