import { Article } from 'src/model/response/news.models'
import { NewsService } from 'src/services/newsService'
import { ref } from 'vue'

const loading = ref(false)
const articles = ref<Article[] | null>(null)

export function useNews() {
  if (!loading.value && articles.value == null) {
    NewsService.getNews().then((value: Article[]) => {
      articles.value = value
      loading.value = false
    })
  }

  return { articles, loading }
}
