<script setup lang="ts">
import { useModelWrapper } from 'src/composables/modelWrapper'
//import { ref } from 'vue'

const emit = defineEmits(['update:modelValue'])
const props = withDefaults(
  defineProps<{
    clickable?: boolean
    icon?: string
    activeClass?: string
    switchToggleSide?: boolean
    label?: string
    caption?: string
    modelValue?: boolean
  }>(),
  {
    clickable: false,
    isSeparator: false,
    closePopup: false,
    switchToggleSide: false,
  }
)

//const expanded = ref(false)

const value = useModelWrapper(props, emit)
</script>

<template>
  <q-expansion-item
    class="d-list-expansion-item"
    v-model="value"
    :icon="icon"
    :switch-toggle-side="switchToggleSide"
    :clickable="clickable"
    :active-class="activeClass"
    :label="label"
    :caption="caption"
  >
    <slot></slot>
  </q-expansion-item>
  <div></div>
</template>

<style scoped lang="scss">
@import '../css/foundations/colors.scss';
@import '../css/foundations/fonts.scss';
</style>
