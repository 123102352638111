<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  name?: string
}>()

const esImg = computed(() => props.name?.startsWith('img:'))
const imgSrc = computed(() => (esImg.value ? props.name?.replace('img:', '') : undefined))
</script>

<template>
  <span
    class="d-icon"
    :class="{ 'material-icons-outlined': !esImg }"
  >
    <template v-if="esImg"><img :src="imgSrc" /></template>
    <template v-else>{{ name }}</template>
  </span>
</template>
<style scoped lang="scss"></style>
