import { useQuasar } from 'quasar'
import DDialog from '../components/ADialog.vue'

export function useDialog() {
  const $q = useQuasar()

  return (
    icon: string,
    title: string,
    content: string,
    align: 'vertical' | 'horizontal' = 'vertical',
    persistent = false,
    textOk: string | undefined = undefined,
    textCancel: string | undefined = undefined
  ) =>
    $q.dialog({
      component: DDialog,

      // props forwarded to your custom component
      componentProps: { icon, title, content, align, persistent, textOk, textCancel },
    })
}
