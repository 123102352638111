import { api, axios } from '../boot/axios'
import { ResetPasswordInfoResponse, TokenResponse } from '../model/response/auth.models'
import { TokenRequest, ResetPasswordRequest, ResetPasswordChangeRequest } from '../model/request/auth.models'
import { AxiosError } from 'axios'
export const AuthService = {
  async getToken(tokenRequest: TokenRequest): Promise<TokenResponse> {
    return (await axios.post<TokenResponse>(process.env.API_URL_BASE + '/token', tokenRequest)).data
  },
  async refreshToken(refreshToken: string): Promise<TokenResponse> {
    const params = {
      refreshToken: refreshToken,
    }
    return (await axios.post<TokenResponse>(process.env.API_URL_BASE + '/token/refresh', params)).data
  },
  async getStatusUserWithoutPassword(login: string): Promise<boolean> {
    const params = {
      login: login,
    }
    try {
      await axios.post(process.env.API_URL_BASE + '/token/status', params)
      return false
    } catch (err) {
      const axiosError = err as AxiosError
      console.log(axiosError)
      if (axiosError?.request?.status != null && axiosError?.request?.status == 404) {
        return true
      }

      return false
    }
  },
  logout(): Promise<void> {
    return api.post('token/revoke')
  },
  async resetPasswordRequestToken(resetPasswordRequest: ResetPasswordRequest): Promise<void> {
    return axios.post(process.env.API_URL_BASE + '/token/password/reset/request', resetPasswordRequest)
  },
  async resetPasswordCheckToken(resetToken: string): Promise<ResetPasswordInfoResponse> {
    return (await axios.get<ResetPasswordInfoResponse>(process.env.API_URL_BASE + '/token/password/reset/' + resetToken)).data
  },
  async resetPasswordChange(resetPasswordChangeRequest: ResetPasswordChangeRequest): Promise<void> {
    return axios.post(process.env.API_URL_BASE + '/token/password/reset', resetPasswordChangeRequest)
  },
}
