import { defineStore } from 'pinia'
import { EmbedReport, EmbedToken } from 'src/model/response/powerBI.models'
import { PowerBIService } from 'src/services/powerBIService'
import { localStorage } from './storage'

export const usePowerBIStore = defineStore('powerBI', {
  state: () => {
    return {
      embedReports: null as { [id: string]: EmbedReport } | null,
      embedToken: null as EmbedToken | null,
      payCapacityEnabled: false,
    }
  },
  getters: {
    isLoaded: (state) => state.embedReports != null || state.embedToken != null,
    isTokenExpired: (state) => {
      return () => {
        console.log('expiration', state.embedToken?.expiration)
        const fechaControl = new Date()
        //5 minutos antes de que caduque forzamos la renovación.
        fechaControl.setMinutes(fechaControl.getMinutes() + 5)
        console.log('fecha Control', fechaControl)

        return state.embedToken == null || state.embedToken.expiration == null || state.embedToken.expiration < fechaControl
      }
    },
    getReport: (state) => {
      return (name: string) => (state.embedReports != null ? state.embedReports[name] : null)
    },
  },
  actions: {
    async refreshPowerBIData() {
      try {
        const powerBIInfo = await PowerBIService.getPowerBIInfo()
        const empedReports = {} as { [id: string]: EmbedReport }
        powerBIInfo.embedReports.forEach((report) => {
          empedReports[report.reportName] = report
        })
        this.$patch((state) => {
          ;(state.embedReports = empedReports), (state.embedToken = powerBIInfo.embedToken), (state.payCapacityEnabled = powerBIInfo.payCapacityEnabled)
        })
      } catch (err) {
        this.$reset()
        throw err
      }
    },
  },
  persist: {
    key: 'powerBI',
    storage: localStorage,
  },
})
