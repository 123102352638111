/* eslint-disable @typescript-eslint/no-explicit-any */
import { boot } from 'quasar/wrappers'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { useAuthStore } from 'src/store/authStore'
import { routerInstance } from 'src/router'

const api = axios.create({ baseURL: process.env.API_URL_BASE })

const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/

function isIsoDateString(value: any): boolean {
  return !(value == null || value === '') && typeof value === 'string' && isoDateFormat.test(value)
}

export function handleDates(body: any): any {
  if (body === null || body === undefined || typeof body !== 'object') return body

  for (const key of Object.keys(body)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const value = body[key]
    if (isIsoDateString(value)) {
      body[key] = dayjs(value).toDate()
    } else if (typeof value === 'object') handleDates(value)
  }
}

api.interceptors.response.use(
  (originalResponse) => {
    handleDates(originalResponse.data)
    return originalResponse
  },
  async (error: AxiosError) => {
    if (error.response != null) {
      if (error.response.status == 401) {
        const { logout } = useAuthStore()
        await logout(routerInstance.currentRoute.value.path)
      }
    }

    return Promise.reject(error)
  }
)
api.interceptors.request.use(async function (config): Promise<AxiosRequestConfig> {
  if (!config.url?.includes('token') || config.url?.includes('revoke')) {
    const { getBearerToken } = useAuthStore()
    if (config.headers != null) config.headers.authorization = await getBearerToken()
    return config
  } else return config
})

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
})

export { axios, api }
