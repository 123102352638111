<script setup lang="ts">
import { useModelWrapper } from '../composables/modelWrapper'

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  name?: string
  label?: string
  hint?: string
  modelValue?: string
  disabled?: boolean
  options: string[]
}>()

const value = useModelWrapper(props, emit)
</script>

<template>
  <q-select
    class="d-select"
    outlined
    :label="label"
    label-color="grey700"
    color="blue600"
    :options="options"
    v-model="value"
    popup-content-class="d-select-menu"
  ></q-select>
</template>
