<script setup lang="ts">
import DFeaturedIcon from './DFeaturedIcon.vue'
import DButton from './DButton.vue'
import { useI18n } from 'src/boot/i18n'

import { useDialogPluginComponent } from 'quasar'

const { t } = useI18n()

defineProps<{
  icon: string
  title: string
  content: string
  align: 'vertical' | 'horizontal'
  persistent: boolean
  textOk?: string
  textCancel?: string
  cancel?: boolean
}>()

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome

// this is part of our example (so not required)
function onOKClick() {
  // on OK, it is REQUIRED to
  // call onDialogOK (with optional payload)
  onDialogOK()
  // or with payload: onDialogOK({ ... })
  // ...and it will also hide the dialog automatically
}
function onCancelClick() {
  // on OK, it is REQUIRED to
  // call onDialogOK (with optional payload)
  onDialogCancel()
  // or with payload: onDialogOK({ ... })
  // ...and it will also hide the dialog automatically
}
</script>

<template>
  <q-dialog
    ref="dialogRef"
    :persistent="persistent"
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin q-pa-md">
      <!--<div class="d-dialog">
    <q-card class="q-pa-md">-->
      <q-card-section class="q-pa-sm">
        <d-featured-icon
          :icon="icon"
          size="lg"
        />
        <q-btn
          color="grey700"
          icon="close"
          size="xs"
          class="float-right"
          flat
          round
          dense
          v-close-popup
        ></q-btn>
      </q-card-section>
      <q-card-section class="q-pa-sm">
        <div class="d-text-xl-semibold text-grey800 q-pb-sm">{{ title }}</div>
        <div class="d-text-sm-regular text-grey500 q-pb-sm">{{ content }}</div>
      </q-card-section>
      <q-card-actions
        class="q-pa-sm"
        vertical
        align="center"
        v-if="align === 'vertical'"
      >
        <d-button
          class="d-width-100"
          md
          priority="priority2"
          @click="onCancelClick"
          >{{ textCancel ?? t('Cancelar') }}</d-button
        >
        <d-button
          class="d-width-100 q-mt-sm"
          md
          priority="priority1"
          @click="onOKClick"
          >{{ textOk ?? t('Aceptar') }}</d-button
        >
      </q-card-actions>
      <q-card-actions
        class="q-pa-none"
        align="around"
        v-else
      >
        <div class="col-6 q-pa-xs">
          <d-button
            class="d-width-100"
            md
            priority="priority2"
            @click="onCancelClick"
            >{{ textCancel ?? t('Cancelar') }}</d-button
          >
        </div>

        <div class="col-6 q-pa-xs">
          <d-button
            class="d-width-100"
            md
            priority="priority1"
            @click="onOKClick"
            >{{ textOk ?? t('Aceptar') }}</d-button
          >
        </div>
      </q-card-actions>
    </q-card>
    <!--</div>-->
  </q-dialog>
</template>

<style scoped lang="scss">
@import '../css/foundations/fonts.scss';
.d-dialog {
  width: 358px;
}

.d-width-100 {
  width: 100%;
}

.q-menu {
  top: 64px !important;
}
.card {
  width: 260px;
  border-radius: var(--border-radius);
}

.q-btn {
  padding: 8px 16px;
  border-radius: var(--border-radius);

  & .block {
    @extend .d-text-sm-medium;
    color: var(--grey-700);
  }

  & .q-icon {
    color: var(--grey-700);
  }
}
</style>
