import { HoraryWeatherResponse, TemporaryResponse, TownsResponse } from 'src/model/response/weather.models'
import { axios, api } from '../boot/axios'

const getUrl = (relativePath: string) => `${process.env.AEMET_API_URL_BASE}${relativePath}?api_key=${process.env.AEMET_API_KEY}`

export const weatherService = {
  async getDiaryWeather(town: string): Promise<HoraryWeatherResponse> {
    const temp = (await axios.get<TemporaryResponse>(getUrl(`prediccion/especifica/municipio/horaria/${town}`))).data
    return (await axios.get<HoraryWeatherResponse>(temp.datos)).data
  },

  async getTowns(): Promise<TownsResponse> {
    return (await axios.get(getUrl('maestro/municipios'))).data
  },

  async getMunicipio(): Promise<string> {
    return (await api.get('/Location')).data
  },
}
