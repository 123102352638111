import { defineStore } from 'pinia'

import { api } from 'src/boot/axios'
import { TokenRequest } from 'src/model/request/auth.models'
import { AuthService } from 'src/services/authService'
import jsrsasign, { RSAKey } from 'jsrsasign'
import { TokenFinalData, TokenInfoRaw, UsuarioLogin } from 'src/model/response/auth.models'
import { useConfigStore } from './configStore'
import { usePowerBIStore } from './powerBIStore'
import { cookieStorage } from './storage'
import { sleep } from 'src/utilities/thread'
import { clearKPIs } from 'src/composables/kpis'
import { clearDashboard } from 'src/composables/dashboard'

export enum Rol {
  TAAFData = '1',
  TAAFDataDashBoard = '1-0',
  TAAFDataGeneral = '1-1',
  TAAFDataPH = '1-2',
  TAAFDataPHGerentes = '1-2-2',
  TAAFDataPHAdministradores = '1-2-3',
  TAAFDataPV = '1-3',
  TAAFDataPVGerentes = '1-3-4',
  TAAFDataPVAdministradores = '1-3-5',
  TAAFDataIMMO = '1-4',
  TAAFDataIMMOGerentes = '1-4-6',
  TAAFDataIMMOAgentesComerciales = '1-4-8',
  TAAFDataConta = '1-5',
  TAAFDataContaGerentes = '1-5-9',
  TAAFDataContaAgentesComerciales = '1-5-10',
  TAAFDataPersonalizado = '1-6',
  GlobalData = '2',
  GlobalDataCalculadoras = '2-2',
  GlobalDataBenchmark = '2-3',
  GlobalDataCalculadorasPresupuestosEIncidencias = '2-2-102',
  GlobalDataCalculadorasIMMO = '2-2-103',
  GlobalDataBenchmarkComunidades = '2-3-104',
  GlobalDataBenchmarkFincas = '2-3-105',
  GlobalDataBenchmarkGlobal = '2-3-106',
}

export enum StatusCodes {
  LOGGING = 1,
  REFRESHING = 2,
  ERROR = 999,
}

const verifyToken = (token: string): boolean => {
  const pubkey = jsrsasign.KEYUTIL.getKey(
    '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2X+Un+v+KxCgBiiQ2eJptMGY2qUYdB9xCc/8L3+5NyWUBg7ADTYBNSSlH3KH/3o2CyhfDQSxh2nScd62UN8oM365UNrcql+rpTx9FVnDhsmUNpDok+NH9DYIBTECxrVkEguykQU3ZUk+jYoR3YSpsIjY7EIip9Edpg2Sh61rLCGtjHrtShIy47JMOkcr8LxLaLo7RPdjHYaIdeKFY+RfOx6Wj0iEagrE+HO5IeiMqfSnl7T0IqpV6yvnkcX69X7IQPcGyVthaUm/0+pgijoY+eZmoId+F0hrm9w5X9UyKYfj8i1utABxGrwYjw4CaFlG9JwJv++hWibmReZBGLO8hQIDAQAB-----END PUBLIC KEY-----',
  )
  return jsrsasign.KJUR.jws.JWS.verifyJWT(token, pubkey as RSAKey, {
    alg: ['RS256'],
  })
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      token: null as string | null,
      refreshTokenId: null as string | null,
      usuario: null as UsuarioLogin | null,
      roles: new Set<Rol>(),
      recordar: false,
      tokenExp: null as number | null,
      globalExp: null as number | null,
      status: null as StatusCodes | null,
      usuarioSinPassword: null as string | null,
    }
  },
  getters: {
    isRefreshing: (state) => state.status == StatusCodes.REFRESHING,
    isLoading: (state) => state.status == StatusCodes.LOGGING || state.status == StatusCodes.REFRESHING,
    isTokenExpired: (state) => {
      return () => state.tokenExp == null || state.tokenExp - Date.now().valueOf() / 1000 <= 0
    },
    isAuthenticated: (state) => {
      return state.token != null && state.refreshTokenId != null && state.usuario != null && state.usuario.idUsuario != null
    },
    isGlobalExpired: (state) => {
      return () => {
        return state.globalExp == null || state.globalExp - Date.now().valueOf() / 1000 <= 0
      }
    },
    isAuthorized: (state) => {
      return (role: Rol) => state.roles.has(role)
    },
    getObjeto: (state) => {
      return (idAplicacion: number, idModulo: number, idObjeto: number) => {
        if (state.usuario != null && state.usuario.objetos != null) {
          return state.usuario.objetos.find((a) => a.idAplicacion == idAplicacion && a.idModulo == idModulo && a.idObjeto == idObjeto)
        }
        return null
      }
    },
    getObjetoPersonalizado: (state) => {
      return (idAplicacion: number, idModulo: number, idObjeto: number) => {
        if (state.usuario != null && state.usuario.objetosPersonalizados != null) {
          return state.usuario.objetosPersonalizados.find((a) => a.idAplicacion == idAplicacion && a.idModulo == idModulo && a.idObjeto == idObjeto)
        }
        return null
      }
    },
    getNombre: (state) => state.usuario?.nombreUsuario.split(' ')[0],
    getNombreUsuarioIniciales: (state) => {
      if (state.usuario == null || state.usuario?.nombreUsuario == null) return null

      const nameParts = state.usuario.nombreUsuario.split(' ')
      let initials = ''

      if (nameParts.length > 1) {
        initials += nameParts[0][0]
        initials += nameParts[1][0]
      } else {
        initials = nameParts[0][0]
      }

      return initials.toUpperCase()
    },
  },
  actions: {
    async login(usuario: string | undefined, password: string | undefined, recordar: boolean, tokenUrl: string | undefined = undefined) {
      this.status = StatusCodes.LOGGING
      const configStore = useConfigStore()

      const tokenRequest: TokenRequest = {
        login: usuario,
        password: password,
        tokenUrl: tokenUrl,
        recordar: recordar,
        idDispositivo: configStore.deviceId,
      }
      try {
        const tokenResponse = await AuthService.getToken(tokenRequest)

        if (tokenResponse != null && verifyToken(tokenResponse.token)) {
          this.tokenSuccess(tokenResponse.token)
          if (!!this.$router.currentRoute.value.query.next) this.$router.replace(this.$router.currentRoute.value.query.next as string)
          else {
            if (this.isAuthorized(Rol.TAAFData)) this.$router.replace('/')
            else if (this.isAuthorized(Rol.GlobalData)) this.$router.replace('/mi-zona')
            else this.$router.replace('/')
          }
        } else {
          this.status = StatusCodes.ERROR
        }
      } catch (error) {
        this.status = StatusCodes.ERROR
        throw error
      }
    },
    async refreshToken() {
      if (this.status != StatusCodes.ERROR && this.refreshTokenId != null) {
        this.status = StatusCodes.REFRESHING
        try {
          const tokenResponse = await AuthService.refreshToken(this.refreshTokenId as string)

          if (tokenResponse != null && verifyToken(tokenResponse.token)) {
            this.tokenSuccess(tokenResponse.token)
          } else {
            this.clearSessionData()
            this.status = StatusCodes.ERROR
          }
        } catch (error) {
          this.clearSessionData()
          this.status = StatusCodes.ERROR
          throw error
        }
      }
    },
    tokenSuccess(token: string) {
      const payloadGlobal = jsrsasign.KJUR.jws.JWS.readSafeJSONString(jsrsasign.b64utoutf8(token.split('.')[1])) as TokenInfoRaw

      const tokenData = jsrsasign.KJUR.jws.JWS.readSafeJSONString(jsrsasign.b64utoutf8(payloadGlobal.token.split('.')[1])) as TokenFinalData

      api.defaults.headers.common['authorization'] = `Bearer ${payloadGlobal.token}`

      this.$patch((state) => {
        ;(state.token = payloadGlobal.token), (state.refreshTokenId = payloadGlobal.refreshToken), (state.usuario = jsrsasign.KJUR.jws.JWS.readSafeJSONString(payloadGlobal.usuario) as UsuarioLogin), (state.tokenExp = tokenData.exp), (state.globalExp = payloadGlobal.globalExp), (state.status = null)
      })

      this.reloadRoles()
    },
    async logout(nextUrl: string | undefined = undefined, error: string | undefined = undefined) {
      if (this.token != null) {
        try {
          await AuthService.logout()
        } catch (error) {}
      }
      api.defaults.headers.common['authorization'] = null
      this.clearSessionData()

      await this.$router.push({ name: 'login', query: { next: nextUrl, error: error } })
    },
    async clearSessionData() {
      console.log('clear session data')
      this.$reset()
      const powerBIStore = usePowerBIStore()
      powerBIStore.$reset()
      clearKPIs()
      clearDashboard()
    },
    async getBearerToken(): Promise<string | null> {
      let tokenActualizado = false
      if (this.status == StatusCodes.REFRESHING) {
        await sleep(1000)
        tokenActualizado = true
      }

      if (!tokenActualizado) {
        if (this.isTokenExpired() && this.refreshToken != null) {
          this.restoreStorage()
          if (this.isTokenExpired() && this.refreshToken != null) {
            try {
              await this.refreshToken()
            } catch (err: unknown) {
              await this.logout(this.$router.currentRoute.value.path)
            }
          }
        }
      }

      return this.token != null ? `Bearer ${this.token}` : null
    },
    async checkExpiration() {
      if (this.isGlobalExpired()) {
        await this.logout()
        return true
      }
      return false
    },
    async reloadRoles() {
      const roles = new Set<Rol>()

      if (this.usuario != null && this.usuario.objetos != null && this.usuario.objetos.length > 0) {
        if (this.usuario.objetos != null && this.usuario.objetos.length > 0)
          this.usuario.objetos.forEach((objeto) => {
            roles.add(objeto.idAplicacion.toString() as Rol)
            roles.add(`${objeto.idAplicacion}-${objeto.idModulo}` as Rol)
            roles.add(`${objeto.idAplicacion}-${objeto.idModulo}-${objeto.idObjeto}` as Rol)
          })
        roles.add(Rol.TAAFDataDashBoard)
      }

      if (this.usuario != null && this.usuario.objetosPersonalizados != null && this.usuario.objetosPersonalizados.length > 0) {
        this.usuario.objetosPersonalizados.forEach((objeto) => {
          roles.add(objeto.idAplicacion.toString() as Rol)
          roles.add(`${objeto.idAplicacion}-${objeto.idModulo}` as Rol)
        })
      }

      this.roles = roles
    },
    restoreStorage() {
      const stateCookie = JSON.parse(cookieStorage.getItem('app-cookie'))
      this.$patch(stateCookie)
      if (this.usuario != null) {
        this.reloadRoles()
      }
      if (this.token != null) {
        api.defaults.headers.common['authorization'] = `Bearer ${this.token}`
      } else api.defaults.headers.common['authorization'] = null
    },
  },
  persist: {
    key: 'app-cookie',
    paths: ['token', 'refreshTokenId', 'usuario', 'recordar', 'tokenExp', 'globalExp'],
    storage: cookieStorage,
    afterRestore: (context) => {
      const token = context.store.token
      context.store.reloadRoles()
      if (token != null) {
        api.defaults.headers.common['authorization'] = `Bearer ${token}`
      } else api.defaults.headers.common['authorization'] = null
    },
  },
})
