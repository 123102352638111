export const keys = {
  CabeceraCalculadoraPresupuestoIncidencias: 'Calculadora.CabeceraCalculadoraPresupuestoIncidencias',
  CabeceraCalculadoraImmo: 'Calculadora.CabeceraCalculadoraImmo',
  Calculadora: 'Calculadora.Calculadora',
  Catastro: 'Calculadora.Catastro',
  CalleNumero: 'Calculadora.CalleNumero',
  Perimetro: 'Calculadora.Perimetro',
  MisFincas: 'Calculadora.MisFincas',
  Mapa: 'Calculadora.Mapa',
  ReferenciaCatastral: 'Calculadora.ReferenciaCatastral',
  Provincia: 'Calculadora.Provincia',
  Municipio: 'Calculadora.Municipio',
  CodigoPostal: 'Calculadora.CodigoPostal',
  Via: 'Calculadora.Via',
  Direccion: 'Calculadora.Direccion',
  Numero: 'Calculadora.Numero',
  AplicarCriteriosBusqueda: 'Calculadora.AplicarCriteriosBusqueda',
  ElijaDireccion: 'Calculadora.ElijaDireccion',
  SinDirecciones: 'Calculadora.SinDirecciones',
  Volver: 'Calculdadora.Volver',
  InformacionEdificio: 'Calculadora.InformacionEdificio',
  RefCatastral: 'Calculadora.RefCatastral',
  MasInformacion: 'Calculadora.MasInformacion',
  CalcularPresupuesto: 'Calculadora.CalcularPresupuesto',
  CalcularIncidencias: 'Calculadora.CalcularIncidencias',
  ListadoPisos: {
    Cabecera: 'Calculadora.ListadoPisos',
    Piso: 'Calculadora.ListadoPisos.Piso',
    MetrosCuadrados:'Calculadora.ListadoPisos.MetrosCuadrados',
    Participacion:'Calculadora.ListadoPisos.Participacion',
    TipoInmueble:'Calculadora.ListadoPisos.TipoInmueble',
    Antiguedad:'Calculadora.ListadoPisos.Antiguedad',
    CuotaElegida:'Calculadora.ListadoPisos.CuotaElegida',
  },
  Errores: {
    GetFeatureError404: 'Calculadora.GetFeatureError404',
    SellRentRangesArea404: 'Calculadora.SellRentRangesArea404',
    GetRoiError400: 'Calculadora.GetRoiError400',
  }
}
