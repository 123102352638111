import { keys } from './keys';

export const es = {
  [keys.indicators.antiguedad_inmuebles.name]: 'Antigüedad inmuebles',
  [keys.indicators.antiguedad_inmuebles.desc]: 'Antigüedad media de inmuebles (años).',
  [keys.indicators.compraventa_viviendas.name]:	'Compraventa de viviendas',
  [keys.indicators.compraventa_viviendas.desc]:	'Número estimado de compraventas de inmuebles.',
  [keys.indicators.compraventa_viviendas_prop.name]: 'Compraventa de viviendas p.c.',
  [keys.indicators.compraventa_viviendas_prop.desc]: 'Número de compraventas de viviendas per cápita (por ciento).',
  [keys.indicators.ejecuciones_hipotecarias.name]: 'Ejecuciones hipotecarias',
  [keys.indicators.ejecuciones_hipotecarias.desc]: 'Número estimado de ejecuciones hipotecarias.',
  [keys.indicators.ejecuciones_hipotecarias_prop.name]:	'Ejecuciones hipotecarias p.c.',
  [keys.indicators.ejecuciones_hipotecarias_prop.desc]:	'Número estimado de ejecuciones hipotecarias per cápita (por ciento).',
  [keys.indicators.incidencias_anuales_en_comunidades.name]: 'Incidencias anuales en comunidades',
  [keys.indicators.incidencias_anuales_en_comunidades.desc]: 'Número de incidencias anuales en comunidades.',
  [keys.indicators.ipv_general.name]:	'IPV (general)',
  [keys.indicators.ipv_general.desc]:	'Valor anual medio del Indice de precios de vivienda (índice general).',
  [keys.indicators.ipv_vivienda_nueva.name]: 'IPV (vivienda nueva)',
  [keys.indicators.ipv_vivienda_nueva.desc]: 'Valor anual medio del Indice de precios de vivienda (vivienda nueva).',
  [keys.indicators.ipv_vivienda_segunda_mano.name]: 'IPV (segunda mano)',
  [keys.indicators.ipv_vivienda_segunda_mano.desc]: 'Valor anual medio del Indice de precios de vivienda (vivienda de segunda mando).',
  [keys.indicators.media_hipotecas.name]:	'Media de hipotecas',
  [keys.indicators.media_hipotecas.desc]:	'Valor medio estimado de hipotecas (€).',
  [keys.indicators.numero_de_inmuebles.name]:	'Inmuebles',
  [keys.indicators.numero_de_inmuebles.desc]:	'Número de inmuebles.',
  [keys.indicators.numero_hipotecas.name]: 'Hipotecas',
  [keys.indicators.numero_hipotecas.desc]: 'Número estimado de hipotecas.',
  [keys.indicators.numero_hipotecas_prop.name]:	'Hipotecas p.c.',
  [keys.indicators.numero_hipotecas_prop.desc]:	'Número estimado de hipotecas per cápita (por ciento).',
  [keys.indicators.plazas_turisticas.name]:	'Plazas turísticas',
  [keys.indicators.plazas_turisticas.desc]:	'Número de plazas turísticas.',
  [keys.indicators.precio_medio_alquiler_fc.name]: 'Precio del alquiler',
  [keys.indicators.precio_medio_alquiler_fc.desc]: 'Precio medio del alquiler (€).',
  [keys.indicators.precio_medio_m2_alquiler_fc.name]:	'Precio del alquiler por m²',
  [keys.indicators.precio_medio_m2_alquiler_fc.desc]:	'Precio medio del alquiler por unidad de superfície (€/m²).',
  [keys.indicators.precio_medio_m2_venta_fc.name]: 'Precio de venta por m²',
  [keys.indicators.precio_medio_m2_venta_fc.desc]: 'Precio medio de venta por unidad de superfície (€/m²).',
  [keys.indicators.precio_medio_venta_fc.name]: 'Precio de venta',
  [keys.indicators.precio_medio_venta_fc.desc]:	'Precio medio de venta (€).',
  [keys.indicators.superficie_inmuebles.name]: 'Superficie de inmuebles',
  [keys.indicators.superficie_inmuebles.desc]: 'Valor medio de superficie de inmuebles (m²).',
  [keys.indicators.transacciones_vivienda.name]: 'Transacciones de vivienda',
  [keys.indicators.transacciones_vivienda.desc]: 'Número estimado de transacciones de vivienda.',
  [keys.indicators.transacciones_vivienda_prop.name]: 'Transacciones de vivienda p.c.',
  [keys.indicators.transacciones_vivienda_prop.desc]: 'Número estimado de transacciones de vivienda per cápita (por ciento).',
  [keys.indicators.viviendas_turisticas.name]: 'Viviendas turísticas',
  [keys.indicators.viviendas_turisticas.desc]: 'Número de viviendas turísticas.',
  [keys.indicators.viviendas_turisticas_prop.name]: 'Viviendas turísticas p.c.',
  [keys.indicators.viviendas_turisticas_prop.desc]: 'Número de viviendas turísticas per cápita (por ciento).',
  [keys.indicators.eficiencia_energetica.name]: 'Eficiencia energética',
  [keys.indicators.eficiencia_energetica.desc]: 'Valor medio de la calificación de eficiencia energética de inmuebles.',
  [keys.indicators.osm_centros_educativos.name]: 'Centros educativos',
  [keys.indicators.osm_centros_educativos.desc]: 'Número de centros educativos: escuelas, institutos, universidades...',
  [keys.indicators.osm_hospitales.name]: 'Hospitales',
  [keys.indicators.osm_hospitales.desc]: 'Número de hospitales.',
  [keys.indicators.osm_supermercados.name]: 'Supermercados',
  [keys.indicators.osm_supermercados.desc]: 'Número de supermercados.',
  [keys.indicators.osm_transporte_publico_bus.name]: 'Transporte público: bus',
  [keys.indicators.osm_transporte_publico_bus.desc]: 'Número de estaciones de transporte público: autobús.',
  [keys.indicators.osm_transporte_publico_trenes.name]: 'Transporte público: tren',
  [keys.indicators.osm_transporte_publico_trenes.desc]: 'Número de estaciones de transporte público: metro, tren y tranvía.',
  [keys.indicators.edad_poblacion.name]: 'Edad media',
  [keys.indicators.edad_poblacion.desc]: 'Edad media de la población (años).',
  [keys.indicators.fecundidad.name]: 'Tasa de Fecundidad',
  [keys.indicators.fecundidad.desc]: 'Tasa Global de Fecundidad media (nacimientos/mil mujeres).',
  [keys.indicators.inmigracion.name]: 'Tasa Bruta de Inmigración',
  [keys.indicators.inmigracion.desc]: 'Tasa Bruta de Inmigración media (inmigraciones/mil habitantes).',
  [keys.indicators.ipc_medio.name]: 'IPC',
  [keys.indicators.ipc_medio.desc]: 'Valor anual medio del Índice de Precios de Consumo.',
  [keys.indicators.ipi_medio.name]: 'IPI',
  [keys.indicators.ipi_medio.desc]: 'Valor anual medio del Índice de Producción Industrial (índice general).',
  [keys.indicators.mortalidad.name]: 'Tasa de Mortalidad',
  [keys.indicators.mortalidad.desc]: 'Tasa de mortalidad media (tanto por mil)',
  [keys.indicators.poblacion.name]: 'Población',
  [keys.indicators.poblacion.desc]: 'Número de habitantes.',
  [keys.indicators.renta_media.name]: 'Renta neta anual',
  [keys.indicators.renta_media.desc]: 'Renta neta media anual por persona (€).',
  [keys.indicators.separaciones.name]: 'Separaciones',
  [keys.indicators.separaciones.desc]: 'Número estimado de separaciones.',
  [keys.indicators.separaciones_prop.name]: 'Separaciones p.c.',
  [keys.indicators.separaciones_prop.desc]: 'Número estimado de separaciones per cápita (por ciento).',
  [keys.indicators.tasa_de_paro.name]: 'Tasa de Paro',
  [keys.indicators.tasa_de_paro.desc]: 'Tasa de Paro media (tanto por ciento)',
  [keys.indicators.total_prestamos.name]: 'Préstamos',
  [keys.indicators.total_prestamos.desc]: 'Número estimado de préstamos.',
  [keys.indicators.total_prestamos_prop.name]: 'Préstamos p.c.',
  [keys.indicators.total_prestamos_prop.desc]: 'Número estimado de préstamos per cápita (por ciento).',
  [keys.indicators.valor_medio_prestamos.name]: 'Valor de préstamos',
  [keys.indicators.valor_medio_prestamos.desc]: 'Valor medio estimado de préstamos.',


  [keys.categories.socioeconomic]: 'Socioeconómicos',
  [keys.categories.quality]: 'Calidad',
  [keys.categories.inmo]: 'Inmo',
}
