<script setup lang="ts">
import { computed } from 'vue'
import DIcon from './DIcon.vue'

const props = withDefaults(
  defineProps<{
    size: 'sm' | 'md' | 'lg'
    icon: string
    color: 'grey' | 'blue' | 'green' | 'orange' | 'red' | 'purple' | 'cyan' | 'warn' | 'error' | 'success'
    invert?: boolean
    borderless?: boolean
    light?: boolean
  }>(),
  {
    size: 'sm',
    color: 'blue',
    borderless: false,
    light: false,
  }
)

const color = computed(() => (props.color === 'green' ? 'success' : props.color === 'orange' ? 'warn' : props.color === 'red' ? 'error' : props.color))
</script>

<template>
  <div :class="`d-featured-icon d-featured-icon-${size} d-featured-icon-${color}${light ? '-light' : invert ? '-invert' : ''} ${borderless ? 'd-borderless' : ''}`">
    <d-icon :name="icon" />
  </div>
</template>

<style scoped lang="scss">
.d-featured-icon {
  border: var(--border-thickness-l);
  border-radius: var(--border-radius-round);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.d-featured-icon.d-borderless {
  border: none;
}

.d-featured-icon-sm {
  width: 32px;
  height: 32px;
  .d-icon {
    font-size: var(--icon-size-xs);
  }
}
.d-featured-icon-md {
  width: 40px;
  height: 40px;

  .d-icon {
    font-size: var(--icon-size-m);
  }
}
.d-featured-icon-lg {
  width: 48px;
  height: 48px;

  .d-icon {
    font-size: var(--icon-size-l);
  }
}
.d-featured-icon-grey {
  background-color: var(--grey-100);
  border-color: var(--grey-50);
  color: var(--grey-700);
}
.d-featured-icon-blue {
  background-color: var(--blue-100);
  border-color: var(--blue-50);
  color: var(--blue-700);
}
.d-featured-icon-success {
  background-color: var(--success-100);
  border-color: var(--success-50);
  color: var(--success-700);
}
.d-featured-icon-warn {
  background-color: var(--warn-100);
  border-color: var(--warn-50);
  color: var(--warn-700);
}
.d-featured-icon-error {
  background-color: var(--error-100);
  border-color: var(--error-50);
  color: var(--error-700);
}
.d-featured-icon-purple {
  background-color: var(--purple-100);
  border-color: var(--purple-50);
  color: var(--purple-700);
}
.d-featured-icon-cyan {
  background-color: var(--cyan-100);
  border-color: var(--cyan-50);
  color: var(--cyan-700);
}

.d-featured-icon-grey-invert {
  background-color: var(--grey-400);
  border-color: var(--grey-50);
  color: var(--neutrals-white);
}
.d-featured-icon-blue-invert {
  background-color: var(--blue-400);
  border-color: var(--blue-50);
  color: var(--neutrals-white);
}
.d-featured-icon-success-invert {
  background-color: var(--success-400);
  border-color: var(--success-50);
  color: var(--neutrals-white);
}
.d-featured-icon-warn-invert {
  background-color: var(--warn-400);
  border-color: var(--warn-50);
  color: var(--neutrals-white);
}
.d-featured-icon-error-invert {
  background-color: var(--error-400);
  border-color: var(--error-50);
  color: var(--neutrals-white);
}
.d-featured-icon-purple-invert {
  background-color: var(--purple-400);
  border-color: var(--purple-50);
  color: var(--neutrals-white);
}
.d-featured-icon-cyan-invert {
  background-color: var(--cyan-400);
  border-color: var(--cyan-50);
  color: var(--neutrals-white);
}

.d-featured-icon-grey-light {
  background-color: var(--grey-50);
  border-color: var(--grey-50);
  color: var(--grey-500);
}
.d-featured-icon-blue-light {
  background-color: var(--blue-50);
  border-color: var(--blue-50);
  color: var(--blue-500);
}
.d-featured-icon-success-light {
  background-color: var(--success-50);
  border-color: var(--success-50);
  color: var(--success-500);
}
.d-featured-icon-warn-light {
  background-color: var(--warn-50);
  border-color: var(--warn-50);
  color: var(--warn-500);
}
.d-featured-icon-error-light {
  background-color: var(--error-50);
  border-color: var(--error-50);
  color: var(--error-500);
}
</style>
