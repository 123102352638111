/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, computed, nextTick, onMounted, onUnmounted, Ref } from 'vue'
import { useI18n } from 'src/boot/i18n'
import { GridItemHTMLElement, GridStack } from 'gridstack'
import { DashboardWidget, DashboardType } from 'src/model/response/user.models'
import { UserService } from 'src/services/userService'
import { Widget, WidgetGroup, WidgetTypeProps, WidgetTypes } from 'src/model/dashboard.models'
import DWidgetTypeSelector from 'src/components/DWidgetTypeSelector.vue'
import { useKPIs } from './kpis'
import { sleep } from 'src/utilities/thread'
import { useQuasar } from 'quasar'
import { onBeforeRouteLeave } from 'vue-router'
import { useDialog } from 'src/composables/dialog'
import { useAuthStore } from 'src/store/authStore'

let widgetsItems: { [type: string]: DashboardWidget[] } = {}

export function clearDashboard() {
  widgetsItems = {}
}

export function useDashboard(type: DashboardType, userName: string) {
  const { t } = useI18n()

  const dashboardType = ref(type)

  const widgetsTypesBase: { [type: string]: WidgetTypeProps } = {
    extra: { h: 1, w: 1, minH: 1, minW: 1, maxH: 1, maxW: 1 },
    extra_time: { h: 1, w: 1, minH: 1, minW: 1, maxH: 1, maxW: 1 },
    extra_weather: { h: 1, w: 1, minH: 1, minW: 1, maxH: 1, maxW: 1 },
    kpi: { h: 1, w: 1, minH: 1, minW: 1, maxH: 1, maxW: 2 },
    kpiGraph: { h: 2, w: 2, minH: 1, minW: 1, maxH: 2, maxW: 2 },
    graph: { h: 2, w: 2, minH: 2, minW: 1, maxH: 3, maxW: 2 },
    notification: { h: 3, w: 1, minH: 2, minW: 1, maxH: 6, maxW: 1 },
    news_H: { h: 1, w: 1, minH: 2, minW: 2, maxH: 2, maxW: 4 },
    news_V: { h: 2, w: 1, minH: 1, minW: 1, maxH: 6, maxW: 1 },
  }
  let grid: GridStack | null = null
  const items = ref<DashboardWidget[]>([])
  const count = ref(0)
  const maxColumnas = 4
  const minColumnWidth = 300
  const maxRowHeight = 154
  const columns = ref(4)
  const hasChanges = ref(false)
  const layout = 'none'
  const editModeEnabled = ref(false)

  const resizeGrid = () => {
    console.log('resizeGrid Call')
    console.log('columns value', columns.value)
    const valorAnteriorCol = columns.value
    if (grid != null && grid.el.clientWidth != 0) {
      console.log('resizeGrid clientWidth', grid.el.clientWidth)
      const gridWidth = grid.el.clientWidth
      const newColumns = Math.floor(gridWidth / minColumnWidth)
      columns.value = newColumns > 0 ? newColumns : 1
      if (columns.value >= maxColumnas) {
        columns.value = maxColumnas
      } else {
        if (columns.value % 2 != 0 && columns.value > 1) columns.value -= 1
      }
      if (columns.value != valorAnteriorCol) {
        console.log('Ajustando columnas')
        grid.column(columns.value, layout).cellHeight(maxRowHeight + 'px')
      }
    }
  }

  const sortGridCustom = () => {
    if (grid != null && grid.engine != null) {
      const engine = grid.engine
      if (engine.nodes.length === 0) return
      engine.batchUpdate().sortNodes()
      const copyNodes = grid?.engine.nodes
      engine.nodes = [] // pretend we have no nodes to conflict layout to start with...
      copyNodes.forEach((node) => {
        if (!node.locked) {
          node.autoPosition = true
        }
        engine.addNode(node, false) // 'false' for add event trigger
        ;(node as any)._dirty = true // will force attr update
      })
      engine.batchUpdate(false)
    }
  }

  const load = async (force = false) => {
    if (widgetsItems[dashboardType.value] == undefined || force) widgetsItems[dashboardType.value] = await UserService.getDashboardWidgets(dashboardType.value)

    const preItems = JSON.parse(JSON.stringify(widgetsItems[dashboardType.value])) as DashboardWidget[]
    preItems.forEach((item) => {
      let typeName = item.type.split('_')[0]
      if (item.type.startsWith('news_')) typeName = item.type
      const type = widgetsTypesBase[typeName]
      if (type != null) {
        item.minH = type.minH
        item.minW = type.minW
        item.maxH = type.maxH
        item.maxW = type.maxW
        item.noResize = type.maxH == type.minH && type.maxW == type.minW
      }
    })
    items.value = preItems
    nextTick(() => {
      if (grid != null) {
        items.value.forEach((item) => {
          const id = parseInt(item.id.replace('w_', ''))
          if (id > count.value) count.value = id
          grid?.makeWidget(item.id as string)
        })
        nextTick(() => {
          sortGridCustom()
        })
      }
    })
  }

  const save = async () => {
    const gridItems = grid?.getGridItems()
    if (gridItems != undefined) refreshXY(gridItems, items.value as DashboardWidget[])
    sortItems(items.value as DashboardWidget[])
    widgetsItems[dashboardType.value] = JSON.parse(JSON.stringify(items.value as DashboardWidget[])) as DashboardWidget[]

    UserService.saveDashboardWidgets(items.value as DashboardWidget[], dashboardType.value)
    hasChanges.value = false
    toggleEdit()
  }

  const restoreState = () => {
    items.value.forEach((item) => {
      grid?.removeWidget(`#${item.id}`, false)
    })
    items.value.length = 0
    load(true)
  }

  const toggleEdit = () => {
    if (editModeEnabled.value && hasChanges.value) {
      dialog('dashboard', t('PersonalizacionPendienteGuardar'), t('PreguntaPersonalizacionDashboardPendienteGuardar'), 'horizontal', false, t('Si'), t('Cancelar')).onOk(() => {
        editModeEnabled.value = !editModeEnabled.value
        hasChanges.value = false
        if (grid != null) {
          grid.setStatic(!editModeEnabled.value)
        }
        restoreState()
      })
    } else {
      editModeEnabled.value = !editModeEnabled.value
      if (grid != null) {
        grid.setStatic(!editModeEnabled.value)
      }
      hasChanges.value = false
    }
  }

  const sortItems = (items: DashboardWidget[]) => {
    // return items.sort((a, b) => {
    //   if (a.x === b.x) {
    //     return (a.y ?? 0) - (b.y ?? 0)
    //   }
    //   return (a.x ?? 0) - (b.x ?? 0)
    // })
    return items.sort((a, b) => {
      if (a.y === b.y) {
        return (a.x ?? 0) - (b.x ?? 0)
      }
      return (a.y ?? 0) - (b.y ?? 0)
    })
  }

  const refreshXY = (origen: GridItemHTMLElement[], destination: DashboardWidget[]) => {
    const resultado = []

    // Crear un mapa de los elementos de listaA por su id para buscar más eficientemente
    const mapaA = new Map()
    for (const elementoA of origen) {
      mapaA.set(elementoA.id, elementoA.gridstackNode)
    }

    // Actualizar los valores de x e Y en los elementos correspondientes de listaB
    for (const elementoB of destination) {
      const elementoA = mapaA.get(elementoB.id)
      if (elementoA) {
        elementoB.x = elementoA.x
        elementoB.y = elementoA.y
      }
      resultado.push(elementoB)
    }
  }

  type Rect = { x: number; y: number; w: number; h: number }
  function findPosition(items: Rect[], w: number, h: number, maxCol: number): { x: number; y: number } {
    let positionFound = false
    let posX = 0
    let posY = 0
    // Comprueba si un rectángulo se superpone con otro.
    function overlaps(rectA: Rect, rectB: Rect): boolean {
      return rectA.x < rectB.x + rectB.w && rectA.x + rectA.w > rectB.x && rectA.y < rectB.y + rectB.h && rectA.y + rectA.h > rectB.y
    }
    while (!positionFound) {
      const newRect: Rect = {
        x: posX,
        y: posY,
        w: w,
        h: h,
      }
      let hasOverlap = false
      for (const item of items) {
        if (overlaps(newRect, item)) {
          hasOverlap = true
          break
        }
      }
      if (!hasOverlap) {
        positionFound = true
      } else {
        // Si hay superposición, prueba la siguiente posición en la misma fila.
        posX += 1
        // Si posX excede el ancho de la tabla, pasa a la siguiente fila y reinicia posX a 0.
        if (posX >= maxCol) {
          posX = 0
          posY += 1
        }
      }
    }
    return { x: posX, y: posY }
  }

  const remove = (widget: any) => {
    if (grid != null) {
      const index = items.value.findIndex((w) => w.id == widget.id)
      items.value.splice(index, 1)
      const selector = `#${widget.id}`
      grid.removeWidget(selector, false)
      hasChanges.value = true
      nextTick(() => {
        sortGridCustom()
      })
    }
  }

  const widgetsGroups: { [type: string]: WidgetGroup } = {
    kpi: { title: t('WidgetTypes.kpi'), icon: 'fmd_bad', id: 'kpi' },
    kpiGraph: { title: t('WidgetTypes.kpiGraph'), icon: 'data_exploration', id: 'kpiGraph' },
    //pieChart: { title: t('WidgetTypes.pieChart'), icon: 'pie_chart_outline', id: 'pieChart' },
    //barChart: { title: t('WidgetTypes.barChart'), icon: 'bar_chart', id: 'barChart' },
    //table: { title: t('WidgetTypes.table'), icon: 'table_rows', id: 'table' },
    notification: { title: t('WidgetTypes.notification'), icon: 'list_alt', id: 'notification' },
    news: { title: t('WidgetTypes.news'), icon: 'newspaper', id: 'news' },
    extra: { title: t('WidgetTypes.extra'), icon: 'widgets', id: 'extra' },
  }
  const widgetTypes: WidgetTypes = {}
  const $q = useQuasar()

  const editingDhasboardWidget: Ref<DashboardWidget | undefined> = ref()
  const openModalEditWidget = async (type: string, idEditingWidget: string | undefined = undefined) => {
    const group = widgetsGroups[type]
    editingDhasboardWidget.value = items.value.find((a) => a.id == idEditingWidget) as DashboardWidget | undefined
    let selectedWidget: Widget | undefined = undefined
    let showOrientation = false

    if (widgetTypes[type] == undefined) {
      switch (type) {
        case 'kpi':
        case 'kpiGraph':
          const { kpisLoading, kpisAgrupado } = useKPIs('year', -1)
          while (kpisLoading.value) {
            await sleep(200)
          }

          const defaultProps = widgetsTypesBase[type]

          if (kpisAgrupado.value != undefined) {
            widgetTypes[type] = []

            for (const kpiId in kpisAgrupado.value) {
              const kpi = kpisAgrupado.value[kpiId]
              console.log('kpi', kpi)
              if ((type == 'kpi' || kpi.tipoKPI == 2) && !kpiId.startsWith('R')) widgetTypes[type].push({ type: `${type}_${kpiId}`, modulo: kpi.modulo, title: kpi.indicador, ...defaultProps })
            }
          }
          console.log('type', widgetTypes[type])
          break
        case 'notification':
          widgetTypes[type] = []
          const defaultPropsNotification = widgetsTypesBase[type]
          widgetTypes[type].push({ type: `${type}_trends`, modulo: 'notification', title: t('CambiosTendencia'), ...defaultPropsNotification })
          break
        case 'extra':
          widgetTypes[type] = []
          const defaultPropsExtra = widgetsTypesBase[type]
          widgetTypes[type].push({ type: `${type}_time`, modulo: 'extra', title: t('Hora'), ...defaultPropsExtra })
          widgetTypes[type].push({ type: `${type}_weather`, modulo: 'extra', title: t('ElTiempo'), ...defaultPropsExtra })
          break
        case 'news':
          widgetTypes[type] = []

          const baseNewsV = widgetsTypesBase[type + '_V']
          widgetTypes[type].push({ type: `${type}_V`, modulo: 'news', title: t('Noticias'), ...baseNewsV })
          const baseNewsH = widgetsTypesBase[type + '_H']
          widgetTypes[type].push({ type: `${type}_H`, modulo: 'news', title: t('Noticias'), ...baseNewsH })

          break
      }
    }

    if (type == 'news') showOrientation = true

    if (editingDhasboardWidget.value != undefined) {
      selectedWidget = Object.assign(
        {},
        widgetTypes[type].find((a) => a.type == editingDhasboardWidget.value?.type),
      )
      selectedWidget.h = editingDhasboardWidget.value.h
      selectedWidget.w = editingDhasboardWidget.value.w
    }

    $q.dialog({
      component: DWidgetTypeSelector,

      // props forwarded to your custom component
      componentProps: { type: group.id, title: group.title, widgets: widgetTypes[type], icon: group.icon, selectedWidget: selectedWidget, showOrientation },
    })
      .onOk((widget: Widget) => {
        if (editingDhasboardWidget.value != undefined) {
          editWidget(widget, editingDhasboardWidget.value)
        } else addNewWidget(widget)
      })
      // .onCancel(() => {
      //   console.log('Cancel')
      // })
      .onDismiss(() => {
        editingDhasboardWidget.value = undefined
      })
  }

  const editWidget = (widget: Widget, currentDashboardWidget: DashboardWidget) => {
    currentDashboardWidget.type = widget.type

    const dimensionesModificadas = currentDashboardWidget.w != widget.w || currentDashboardWidget.h != widget.h
    currentDashboardWidget.w = widget.w
    currentDashboardWidget.h = widget.h

    hasChanges.value = true
    if (dimensionesModificadas) {
      const item = grid?.getGridItems().find((a) => a.id == currentDashboardWidget.id)
      if (item?.gridstackNode != null) {
        item.gridstackNode.h = widget.h
        item.gridstackNode.w = widget.w
      }

      nextTick(() => {
        sortGridCustom()
      })
    }
  }

  const addNewWidget = (widget: Widget) => {
    if (grid != null) {
      const newPosition = findPosition(items.value as Rect[], widget.w as number, widget.h as number, columns.value)
      const node = items.value[count.value] || {
        minW: widget.minW,
        minH: widget.minH,
        x: newPosition.x,
        y: newPosition.y,
        w: widget.w,
        h: widget.h,
        type: widget.type,
        maxH: widget.maxH,
        maxW: widget.maxW,
        noResize: widget.maxH == widget.minH && widget.maxW == widget.minW,
      }

      count.value++
      node.id = 'w_' + count.value
      items.value.push(node)
      hasChanges.value = true
      nextTick(() => {
        if (grid != null) {
          grid.makeWidget(node.id as string)
        }
      })
    }
  }

  const title = computed(() => {
    if (dashboardType.value == 'portal' || dashboardType.value == 'data') {
      return `${t('Bienvenido')}, ${userName}`
    } else return t('Dashboard')
  })

  const onChange = (event: Event, changeItems: any[]) => {
    changeItems.forEach((item) => {
      const widget = items.value.find((w) => w.id == item.id)
      if (!widget) {
        return
      }
      widget.x = item.x
      widget.y = item.y
      widget.w = item.w
      widget.h = item.h
    })
    if (editModeEnabled.value) hasChanges.value = true
    nextTick(() => {
      sortGridCustom()
    })
  }

  const initGrid = () => {
    grid = GridStack.init({
      // DO NOT user grid.value = GridStack.init(), see above
      float: false,
      column: maxColumnas,
      cellHeight: maxRowHeight,
      minRow: 1,
      margin: 8,
      disableOneColumnMode: true,
      resizable: {
        handles: 'e,se,s,sw,w,n,ne,nw,',
      },
      staticGrid: true,
    })
    resizeGrid()
    grid.on('change', onChange)
    grid.compact()
  }
  const resizeObserver = new ResizeObserver(resizeGrid)
  onMounted(() => {
    initGrid()
    load()
    if (grid != null) resizeObserver.observe(grid.el)
  })

  onUnmounted(() => {
    if (grid != null) resizeObserver.unobserve(grid.el)
    resizeObserver.disconnect()
  })

  const changeType = (newType: DashboardType) => {
    if (editModeEnabled.value) editModeEnabled.value = false
    dashboardType.value = newType
    dashboardType.value = newType
    items.value.forEach((item) => {
      grid?.removeWidget(`#${item.id}`, false)
    })
    items.value.length = 0
    load()
  }

  const dialog = useDialog()
  onBeforeRouteLeave((to, from, next) => {
    if (hasChanges.value) {
      const { isAuthenticated, isGlobalExpired } = useAuthStore()
      if (isAuthenticated && !isGlobalExpired()) {
        dialog('dashboard', t('PersonalizacionPendienteGuardar'), t('PreguntaPersonalizacionDashboardPendienteGuardar'), 'horizontal', false, t('Si'), t('Cancelar'))
          .onOk(() => {
            next()
          })
          .onCancel(() => {
            next(false)
          })
      }
    } else next()
  })

  return { title, save, toggleEdit, addNewWidget, editModeEnabled, hasChanges, items, remove, changeType, widgetsGroups, openModalEditWidget, columns }
}
