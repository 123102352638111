export const keys = {
  PH: {
      Titulo: 'BM.PH.Titulo',
      Cartera: {
        Titulo: 'BM.PH.Cartera.Titulo',
        Descripcion: { '0': 'BM.PH.Cartera.Descripcion.0' }
      },

      FacturacionTotal: {
        Titulo: 'BM.PH.FacturacionTotal.Titulo',
        Descripcion: {
          '0': 'BM.PH.FacturacionTotal.Descripcion.0',
          '1': 'BM.PH.FacturacionTotal.Descripcion.1',
          '2': 'BM.PH.FacturacionTotal.Descripcion.2'
        }
      },

      FacturacionCartera: {
        Titulo: 'BM.PH.FacturacionCartera.Titulo',
        Descripcion: {
          '0': 'BM.PH.FacturacionCartera.Descripcion.0',
          '1': 'BM.PH.FacturacionCartera.Descripcion.1',
          '2': 'BM.PH.FacturacionCartera.Descripcion.2'
        },
      },

      Eficiencia: {
        Titulo: 'BM.PH.Eficiencia.Titulo',
        Descripcion: {
          '0': 'BM.PH.Eficiencia.Descripcion.0',
          '1': 'BM.PH.Eficiencia.Descripcion.1',
          '2': 'BM.PH.Eficiencia.Descripcion.2'
        }
      },
      Impagos: {
        Titulo: 'BM.PH.Impagos.Titulo',
        Descripcion: {
          '0': 'BM.PH.Impagos.Descripcion.0',
          '1': 'BM.PH.Impagos.Descripcion.1',
          '2': 'BM.PH.Impagos.Descripcion.2',
          '3': 'BM.PH.Impagos.Descripcion.3'
        }
      },

      PH1: {cabecera:'BM.PH.PH1.cabecera',tooltip:'BM.PH.PH1.tooltip'},
      PH2: {cabecera:'BM.PH.PH2.cabecera',tooltip:'BM.PH.PH2.tooltip'},
      PH3: {cabecera:'BM.PH.PH3.cabecera',tooltip:'BM.PH.PH3.tooltip'},
      PH4: {cabecera:'BM.PH.PH4.cabecera',tooltip:'BM.PH.PH4.tooltip'},
      PH5: {cabecera:'BM.PH.PH5.cabecera',tooltip:'BM.PH.PH5.tooltip'},
      PH6: {cabecera:'BM.PH.PH6.cabecera',tooltip:'BM.PH.PH6.tooltip'},

      PH27: {cabecera:'BM.PH.PH27.cabecera', tooltip: 'BM.PH.PH27.tooltip'},

      PH36: {cabecera:'BM.PH.PH36.cabecera', tooltip: 'BM.PH.PH36.tooltip'},
      PH37: {cabecera:'BM.PH.PH37.cabecera', tooltip: 'BM.PH.PH37.tooltip'},

      RPH1: {cabecera:'BM.PH.RPH1.cabecera', tooltip: 'BM.PH.RPH1.tooltip'},

      RPH3: {cabecera:'BM.PH.RPH3.cabecera', tooltip: 'BM.PH.RPH3.tooltip'},
      RPH4: {cabecera:'BM.PH.RPH4.cabecera', tooltip: 'BM.PH.RPH4.tooltip'},

      RPH6: {cabecera:'BM.PH.RPH6.cabecera', tooltip: 'BM.PH.RPH6.tooltip'},

      RPH8: {cabecera:'BM.PH.RPH8.cabecera', tooltip: 'BM.PH.RPH8.tooltip'},
      RPH9: {cabecera:'BM.PH.RPH9.cabecera', tooltip: 'BM.PH.RPH9.tooltip'},

      RPH12: {cabecera:'BM.PH.RPH12.cabecera', tooltip: 'BM.PH.RPH12.tooltip'},

      RPH15: {cabecera:'BM.PH.RPH15.cabecera', tooltip: 'BM.PH.RPH15.tooltip'},

      RPH18: {cabecera:'BM.PH.RPH18.cabecera', tooltip: 'BM.PH.RPH18.tooltip'},
      RPH19: {cabecera:'BM.PH.RPH19.cabecera', tooltip: 'BM.PH.RPH19.tooltip'},

      RPH21: {cabecera:'BM.PH.RPH21.cabecera', tooltip: 'BM.PH.RPH21.tooltip'},

      RPH23: {cabecera:'BM.PH.RPH23.cabecera', tooltip: 'BM.PH.RPH23.tooltip'},

      RPH27: {cabecera:'BM.PH.RPH2.cabecera', tooltip: 'BM.PH.RPH27.tooltip'},

      RPH30: {cabecera:'BM.PH.RPH30.cabecera', tooltip: 'BM.PH.RPH30.tooltip'},
      RPH31: {cabecera:'BM.PH.RPH31.cabecera', tooltip: 'BM.PH.RPH31.tooltip'},
      RPH32: {cabecera:'BM.PH.RPH32.cabecera', tooltip: 'BM.PH.RPH32.tooltip'},

      RPH37: {cabecera:'BM.PH.RPH37.cabecera', tooltip: 'BM.PH.RPH37.tooltip'},
    },
    PV: {
      Titulo: 'BM.PV.Titulo',
      Cartera: {
        Titulo: 'BM.PV.Cartera.Titulo',
        Descripcion: {
          '0': 'BM.PV.Cartera.Descripcion.0',
          '1': 'BM.PV.Cartera.Descripcion.1',
          '2': 'BM.PV.Cartera.Descripcion.2',
        }
      },
      FacturacionTotal: {
        Titulo: 'BM.PV.FacturacionTotal.Titulo',
        Descripcion: {
          '0': 'BM.PV.FacturacionTotal.Descripcion.0',
          '1': 'BM.PV.FacturacionTotal.Descripcion.1',
          '2': 'BM.PV.FacturacionTotal.Descripcion.2'
        }
      },
      FacturacionCartera: {
        Titulo: 'BM.PV.FacturacionCartera.Titulo',
        Descripcion: {
          '0': 'BM.PV.FacturacionCartera.Descripcion.0',
          '1': 'BM.PV.FacturacionCartera.Descripcion.1'
        }
      },
      Eficiencia: {
        Titulo: 'BM.PV.Eficiencia.Titulo',
        Descripcion: {
          '0': 'BM.PV.Eficiencia.Descripcion.0',
          '1': 'BM.PV.Eficiencia.Descripcion.1',
          '2': 'BM.PV.Eficiencia.Descripcion.2'
        }
      },
      Impagos: {
        Titulo: 'BM.PV.Impagos.Titulo',
        Descripcion: {
          '0': 'BM.PV.Impagos.Descripcion.0',
          '1': 'BM.PV.Impagos.Descripcion.1',
          '2': 'BM.PV.Impagos.Descripcion.2'
        }
      },
      PV1: {cabecera:'BM.PV.PV1.cabecera', tooltip: 'BM.PV.PV1.tooltip'},
      PV2: {cabecera:'BM.PV.PV2.cabecera', tooltip: 'BM.PV.PV2.tooltip'},
      PV3: {cabecera:'BM.PV.PV3.cabecera', tooltip: 'BM.PV.PV3.tooltip'},
      PV4: {cabecera:'BM.PV.PV4.cabecera', tooltip: 'BM.PV.PV4.tooltip'},

      PV6: {cabecera:'BM.PV.PV6.cabecera', tooltip: 'BM.PV.PV6.tooltip'},

      PV26: {cabecera:'BM.PV.PV26.cabecera', tooltip: 'BM.PV.PV26.tooltip'},
      PV27: {cabecera:'BM.PV.PV27.cabecera', tooltip: 'BM.PV.PV27.tooltip'},
      PV28: {cabecera:'BM.PV.PV28.cabecera', tooltip: 'BM.PV.PV28.tooltip'},

      PV37: {cabecera:'BM.PV.PV37.cabecera', tooltip: 'BM.PV.PV37.tooltip'},
      PV38: {cabecera:'BM.PV.PV38.cabecera', tooltip: 'BM.PV.PV38.tooltip'},
      PV39: {cabecera:'BM.PV.PV39.cabecera', tooltip: 'BM.PV.PV39.tooltip'},

      PV41: {cabecera:'BM.PV.PV41.cabecera', tooltip: 'BM.PV.PV41.tooltip'},

      RPV1: {cabecera:'BM.PV.RPV1.cabecera', tooltip: 'BM.PV.RPV1.tooltip'},

      RPV4: {cabecera:'BM.PV.RPV4.cabecera', tooltip: 'BM.PV.RPV4.tooltip'},

      RPV7: {cabecera:'BM.PV.RPV7.cabecera', tooltip: 'BM.PV.RPV7.tooltip'},
      RPV8: {cabecera:'BM.PV.RPV8.cabecera', tooltip: 'BM.PV.RPV8.tooltip'},

      RPV15: {cabecera:'BM.PV.RPV15.cabecera', tooltip: 'BM.PV.RPV15.tooltip'},
      RPV16: {cabecera:'BM.PV.RPV16.cabecera', tooltip: 'BM.PV.RPV16.tooltip'},

      RPV20: {cabecera:'BM.PV.RPV20.cabecera', tooltip: 'BM.PV.RPV20.tooltip'},
      RPV21: {cabecera:'BM.PV.RPV21.cabecera', tooltip: 'BM.PV.RPV21.tooltip'},

      RPV23: {cabecera:'BM.PV.RPV23.cabecera', tooltip: 'BM.PV.RPV23.tooltip'},
      RPV24: {cabecera:'BM.PV.RPV24.cabecera', tooltip: 'BM.PV.RPV24.tooltip'},

      RPV29: {cabecera:'BM.PV.RPV29.cabecera', tooltip: 'BM.PV.RPV29.tooltip'},

      RPV36: {cabecera:'BM.PV.RPV36.cabecera', tooltip: 'BM.PV.RPV36.tooltip'},
      RPV37: {cabecera:'BM.PV.RPV37.cabecera', tooltip: 'BM.PV.RPV37.tooltip'},
      RPV38: {cabecera:'BM.PV.RPV38.cabecera', tooltip: 'BM.PV.RPV38.tooltip'},
    },
    GEN:{
      Titulo: 'BM.GEN.Titulo',
      DesarrolloNegocio: {
        Titulo: 'BM.GEN.DesarrolloNegocio.Titulo',
        Descripcion: {
          '0': 'BM.GEN.DesarrolloNegocio.Descripcion.0',
          '1': 'BM.GEN.DesarrolloNegocio.Descripcion.1',
          '2': 'BM.GEN.DesarrolloNegocio.Descripcion.2',
          '3': 'BM.GEN.DesarrolloNegocio.Descripcion.3',
        }
      },
      Facturacion: {
        Titulo: 'BM.GEN.Facturacion.Titulo',
        Descripcion: {
          '0': 'BM.GEN.Facturacion.Descripcion.0',
        }
      },
      Rentabilidad: {
        Titulo: 'BM.GEN.Rentabilidad.Titulo',
        Descripcion: {
          '0': 'BM.GEN.Rentabilidad.Descripcion.0',
        }
      },
      ExcelenciaOperativa: {
        Titulo: 'BM.GEN.ExcelenciaOperativa.Titulo',
        Descripcion: {
          '0': 'BM.GEN.ExcelenciaOperativa.Descripcion.0',
        }
      },

      GEN1: {cabecera: 'BM.GEN.GEN1.cabecera', tooltip: 'BM.GEN.GEN1.tooltip'},

      GEN4: {cabecera: 'BM.GEN.GEN4.cabecera', tooltip: 'BM.GEN.GEN4.tooltip' },

      GEN29: {cabecera: 'BM.GEN.GEN29.cabecera', tooltip: 'BM.GEN.GEN29.tooltip'},

      RGEN7: {cabecera: 'BM.GEN.RGEN7.cabecera', tooltip: 'BM.GEN.RGEN7.tooltip' },
      RGEN8: {cabecera: 'BM.GEN.RGEN8.cabecera', tooltip: 'BM.GEN.RGEN8.tooltip'},
      RGEN9: {cabecera: 'BM.GEN.RGEN9.cabecera', tooltip: 'BM.GEN.RGEN9.tooltip'},

      RGEN12: {cabecera: 'BM.GEN.RGEN12.cabecera', tooltip: 'BM.GEN.RGEN12.tooltip' },
      RGEN13: {cabecera: 'BM.GEN.RGEN13.cabecera', tooltip: 'BM.GEN.RGEN13.tooltip' },

      RGEN15: {cabecera: 'BM.GEN.RGEN15.cabecera', tooltip: 'BM.GEN.RGEN15.tooltip' },
      RGEN16: {cabecera: 'BM.GEN.RGEN16.cabecera', tooltip: 'BM.GEN.RGEN16.tooltip'},

      RGEN18: {cabecera: 'BM.GEN.RGEN18.cabecera', tooltip: 'BM.GEN.RGEN18.tooltip' },
      RGEN19: {cabecera: 'BM.GEN.RGEN19.cabecera', tooltip: 'BM.GEN.RGEN19.tooltip'},

      RGEN22: {cabecera: 'BM.GEN.RGEN22.cabecera', tooltip: 'BM.GEN.RGEN22.tooltip'},
      RGEN23: {cabecera: 'BM.GEN.RGEN23.cabecera', tooltip: 'BM.GEN.RGEN23.tooltip'},

      RGEN27: {cabecera: 'BM.GEN.RGEN27.cabecera', tooltip: 'BM.GEN.RGEN27.tooltip' },
      RGEN28: {cabecera: 'BM.GEN.RGEN28.cabecera', tooltip: 'BM.GEN.RGEN28.tooltip'},

      RGEN30: {cabecera: 'BM.GEN.RGEN30.cabecera', tooltip: 'BM.GEN.RGEN30.tooltip'},

      RGEN32: {cabecera: 'BM.GEN.RGEN32.cabecera', tooltip: 'BM.GEN.RGEN32.tooltip' },
      RGEN33: {cabecera: 'BM.GEN.RGEN33.cabecera', tooltip: 'BM.GEN.RGEN33.tooltip'},
      RGEN34: {cabecera: 'BM.GEN.RGEN34.cabecera', tooltip: 'BM.GEN.RGEN34.tooltip'},

    }
  }
