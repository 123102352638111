import { keys } from './keys';

export const es = {
  [keys.CabeceraCalculadoraPresupuestoIncidencias]: 'Presupuestos e Incidencias',
  [keys.CabeceraCalculadoraImmo]: 'IMMO',
  [keys.Calculadora]: 'Calculadora',
  [keys.ReferenciaCatastral]: 'Referencia Catastral',

  [keys.Catastro]: 'Catastro',
  [keys.CalleNumero]: 'Calle / Número',
  [keys.Perimetro]: 'Perímetro',
  [keys.MisFincas]:  'Mis Fincas',
  [keys.Mapa]:  'Mapa',

  [keys.Provincia]: 'Provincia',
  [keys.Municipio]: 'Municipio',
  [keys.CodigoPostal]: 'Código Postal',
  [keys.Via]: 'Vía',
  [keys.Direccion]: 'Dirección',
  [keys.Numero]: 'Número',

  [keys.ReferenciaCatastral]: 'Referencia Catastral',
  [keys.Provincia]: 'Provincia',
  [keys.Municipio]: 'Municipio',
  [keys.CodigoPostal]: 'Código Postal',
  [keys.Via]: 'Vía',
  [keys.Numero]: 'Número',
  [keys.AplicarCriteriosBusqueda]: 'Aplicar criterios de búsqueda',
  [keys.ElijaDireccion]: 'Elija la dirección',
  [keys.SinDirecciones]: 'El usuario actual no tiene fincas asociadas',
  [keys.Volver]: 'Volver',
  [keys.InformacionEdificio]: 'Información del edificio',
  [keys.RefCatastral]: 'Ref. Catastral',
  [keys.MasInformacion]: 'Más Información',
  [keys.CalcularPresupuesto]: 'Calcular Presupuesto',
  [keys.CalcularIncidencias]: 'Calcular Incidencias',
  [keys.ListadoPisos.Cabecera]: 'Listado de pisos',
  [keys.ListadoPisos.Piso]: 'Piso',
  [keys.ListadoPisos.MetrosCuadrados]:'m²',
  [keys.ListadoPisos.Participacion]:'Participación %',
  [keys.ListadoPisos.TipoInmueble]:'Tipo de inmueble',
  [keys.ListadoPisos.Antiguedad]:'Antigüedad',
  [keys.ListadoPisos.CuotaElegida]:'Cuota elegida',
  [keys.Errores.GetFeatureError404]: 'No se ha encontrado ninguna finca con los datos proporcionados.',
  [keys.Errores.SellRentRangesArea404]: 'No se han encontrado datos para las coordenadas especificadas.',
  [keys.Errores.GetRoiError400]: 'Por favor revise los datos proporcionados y vuelva a intentarlo.'
}
