<script setup lang="ts">
import DGridStackWidget from 'src/components/DGridStackWidget.vue'
import DWidgetDummy from 'src/components/DWidgetDummy.vue'
import DIcon from 'src/components/DIcon.vue'
import DButtonClose from './DButtonClose.vue'
import DButton from './DButton.vue'
import AList from 'src/components/AList.vue'
import AListItem from 'src/components/AListItem.vue'
import AListExpansionItem from 'src/components/AListExpansionItem.vue'
import ASelect from './ASelect.vue'
import { useDialogPluginComponent } from 'quasar'
import { Widget } from 'src/model/dashboard.models'
import { Ref, computed, ref, watch, onMounted } from 'vue'
import { useI18n } from 'src/boot/i18n'

const props = withDefaults(defineProps<{ type: string; widgets: Widget[]; icon: string; title: string; selectedWidget?: Widget; showOrientation?: boolean }>(), { showOrientation: false })

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
])
const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent()

const { t } = useI18n()

const vertical = t('Vertical')
const horizontal = t('Horizontal')

const orientations = ref([vertical, horizontal])
const orientation = ref(vertical)
const maximized = ref(false)
const previewWidth = ref(0)
const previewHeigth = ref(0)
const previewDiv: Ref<HTMLDivElement | undefined> = ref()
const showGrouped = ref(true)

const onResize = (size: { width: number; height: number }) => {
  maximized.value = size.width < 900

  if (previewDiv.value != undefined) {
    previewWidth.value = previewDiv.value.offsetWidth
    previewHeigth.value = previewDiv.value.offsetHeight
  }
}

const dimensions = ref(['1x1'])
const selectedDimension = ref(dimensions.value[0])

const loadDimensions = (minH: number, maxH: number, minW: number, maxW: number) => {
  dimensions.value = []
  for (let h = minH; h <= maxH; h++) {
    for (let w = minW; w <= maxW; w++) {
      dimensions.value.push(`${h}x${w}`)
    }
  }
}

const dimensionsW = computed(() => {
  if (selectedDimension.value != null) {
    const split = selectedDimension.value.split('x')
    return +split[1]
  }
  return 1
})
const dimensionsH = computed(() => {
  if (selectedDimension.value != null) {
    const split = selectedDimension.value.split('x')
    return +split[0]
  }
  return 1
})
const dimensionsWpx = computed(() => {
  return dimensionsW.value * 300
})

const dimensionsHpx = computed(() => {
  return dimensionsH.value * 150
})

const centerVertical = computed(() => {
  return previewHeigth.value - 20 > dimensionsHpx.value
})

const centerHorizontal = computed(() => {
  return previewWidth.value - 20 > dimensionsWpx.value
})

const filterTitle = ref('')

const expandedStatus: Ref<{ [id: string]: { expanded: boolean } }> = ref({})
const selectedWidgetRef: Ref<Widget | undefined> = ref()

const filteredWidgets = computed(() => {
  let filteredWidgets = props.widgets

  if (filterTitle.value != '' || props.showOrientation) {
    const normalizedFilter = filterTitle.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
    filteredWidgets = props.widgets.filter(
      (widget) =>
        widget.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(normalizedFilter) &&
        (!props.showOrientation || (props.showOrientation && ((orientation.value == vertical && widget.minH < widget.maxH) || (orientation.value == horizontal && widget.minW < widget.maxW)))),
    )
  }

  // Agrupar los widgets filtrados por la propiedad 'modulo'
  const groupedWidgets = filteredWidgets.reduce(
    (groups, widget) => {
      const modulo = widget.modulo || ''
      if (!groups[modulo]) {
        groups[modulo] = { expanded: ref(false), widgets: [] }
        //if (expandedStatus.value[modulo] == undefined)
        expandedStatus.value[modulo] = { expanded: false }
      }
      groups[modulo].widgets.push(widget)
      return groups
    },
    {} as { [id: string]: { expanded: Ref<boolean>; widgets: Widget[] } },
  )

  return groupedWidgets
})

onMounted(() => {
  if (props.widgets != null && props.widgets.length > 0) {
    if (Object.keys(filteredWidgets.value).length == 1) showGrouped.value = false
    const firstWidget = props.widgets[0]

    loadDimensions(firstWidget.minH, firstWidget.maxH, firstWidget.minW, firstWidget.maxW)
    orientation.value = firstWidget.minH < firstWidget.maxH ? vertical : horizontal

    selectedDimension.value = `${firstWidget.h}x${firstWidget.w}`

    if (props.selectedWidget == null && (props.type == 'news' || props.widgets.length == 1)) {
      selectedWidgetRef.value = Object.assign({}, firstWidget)
    }
  }

  //Asignamos el selectedWidget en caso de haber uno
  if (props.selectedWidget != null) {
    const editedWidget = Object.assign({}, props.selectedWidget)

    selectedWidgetRef.value = editedWidget
    const newOrientation = selectedWidgetRef.value.minH < selectedWidgetRef.value.maxH ? vertical : horizontal
    if (orientation.value != newOrientation) {
      orientation.value = newOrientation
    }
    selectedDimension.value = `${editedWidget?.h}x${editedWidget?.w}`
  }
})

watch(filteredWidgets, (newValue) => {
  if (Object.keys(newValue).length == 1) {
    const modulo = Object.keys(newValue)[0]
    expandedStatus.value[modulo].expanded = true
  }

  if (props.showOrientation && selectedWidgetRef.value != undefined) {
    if ((orientation.value == vertical && selectedWidgetRef.value.minH == selectedWidgetRef.value.maxH) || (orientation.value == horizontal && selectedWidgetRef.value.minW == selectedWidgetRef.value.maxW)) {
      selectedWidgetRef.value = undefined
      if (props.type == 'news') {
        const modulo = Object.keys(newValue)[0]
        selectedWidgetRef.value = Object.assign({}, filteredWidgets.value[modulo].widgets[0])
        selectedWidgetRef.value.h = dimensionsH.value
        selectedWidgetRef.value.w = dimensionsW.value
      }
    }
  }
})

watch(selectedDimension, () => {
  if (selectedWidgetRef.value != null) {
    selectedWidgetRef.value.h = dimensionsH.value
    selectedWidgetRef.value.w = dimensionsW.value
  }
})

watch(orientation, (newValue) => {
  const widget = props.widgets.find((a) => (newValue == vertical && a.minH < a.maxH) || (newValue == horizontal && a.minW < a.maxW))
  if (widget != undefined) {
    loadDimensions(widget.minH, widget.maxH, widget.minW, widget.maxW)
    if (!dimensions.value.includes(selectedDimension.value)) selectedDimension.value = dimensions.value[0]
  }
})

const changeSelectedWidget = (widget: Widget) => {
  console.log('changeSelectedWidget')
  console.log(selectedWidgetRef.value?.type)
  console.log(widget.type)
  if (selectedWidgetRef.value?.type != widget.type) {
    console.log('entra')
    selectedWidgetRef.value = Object.assign({}, widget)
    selectedWidgetRef.value.h = dimensionsH.value
    selectedWidgetRef.value.w = dimensionsW.value
  }
}

const saveWidget = () => {
  onDialogOK(selectedWidgetRef.value)
}
</script>

<template>
  <q-dialog
    :maximized="maximized"
    ref="dialogRef"
    @hide="onDialogHide"
  >
    <q-resize-observer @resize="onResize"></q-resize-observer>
    <div class="d-widget-type-selector">
      <div
        ref="previewDiv"
        class="preview d-scroll"
        :class="{ 'center-vertical': centerVertical, 'center-horizontal': centerHorizontal }"
      >
        <div
          class="widget grid-stack-item-content"
          v-if="!!selectedWidgetRef"
          :style="'width: ' + dimensionsWpx + 'px; height: ' + dimensionsHpx + 'px;'"
        >
          <d-grid-stack-widget
            :type="selectedWidgetRef?.type"
            :h="selectedWidgetRef.h"
            :w="selectedWidgetRef.w"
          ></d-grid-stack-widget>
        </div>
        <div
          class="widget"
          :style="'width: ' + dimensionsWpx + 'px; height: ' + dimensionsHpx + 'px;'"
          v-else
        >
          <d-widget-dummy :icon="icon"></d-widget-dummy>
        </div>
      </div>
      <div class="sidebar">
        <div class="d-widget-type-selector-sidebar">
          <div class="d-widget-type-selector-sidebar-header q-pa-md">
            <div class="d-title d-display-xs-semibold">
              <d-icon
                :name="icon"
                class="q-mr-xs"
              ></d-icon>
              {{ title }}
            </div>

            <d-button-close
              v-close-popup
              class="d-close"
            ></d-button-close>
          </div>
          <div class="d-widget-type-selector-sidebar-search">
            <div
              class="row flex no-wrap"
              v-if="type != 'news'"
            >
              <div class="d-widget-type-selector-sidebar-search-text">
                <d-input-text
                  :placeholder="$t('Buscar')"
                  icon="search"
                  v-model="filterTitle"
                  class="grow"
                ></d-input-text>
              </div>

              <a-select
                v-if="!showOrientation"
                outlined
                dense
                class="d-widget-type-selector-sidebar-search-dimensions"
                v-model="selectedDimension"
                :options="dimensions"
              />
            </div>
            <div
              class="row flex no-wrap"
              :class="{ 'q-mt-md': type != 'news' }"
              v-if="showOrientation"
            >
              <a-select
                outlined
                dense
                class="d-widget-type-selector-sidebar-search-text"
                v-model="orientation"
                :options="orientations"
              />

              <a-select
                outlined
                dense
                class="d-widget-type-selector-sidebar-search-dimensions"
                v-model="selectedDimension"
                :options="dimensions"
              />
            </div>
          </div>
          <div class="d-widget-type-selector-sidebar-result d-scroll">
            <template v-if="type != 'news'">
              <a-list v-if="showGrouped">
                <a-list-expansion-item
                  v-for="(widgets, key) in filteredWidgets"
                  v-model="expandedStatus[widgets.widgets[0].modulo].expanded"
                  :key="key"
                  :label="widgets.widgets[0].modulo"
                >
                  <a-list-item
                    v-for="widget in widgets.widgets"
                    :key="widget.type"
                    clickable
                    :active="selectedWidgetRef?.type == widget.type"
                    @click="changeSelectedWidget(widget)"
                    >{{ widget.title }}</a-list-item
                  >
                </a-list-expansion-item>
              </a-list>
              <a-list v-else>
                <template
                  v-for="(widgets, key) in filteredWidgets"
                  :key="key"
                >
                  <a-list-item
                    v-for="widget in widgets.widgets"
                    :key="widget.type"
                    clickable
                    :active="selectedWidgetRef?.type == widget.type"
                    @click="changeSelectedWidget(widget)"
                    >{{ widget.title }}</a-list-item
                  >
                </template>
              </a-list>
            </template>
          </div>
          <div class="d-widget-type-selector-sidebar-bottom">
            <d-button
              md
              :disabled="selectedWidgetRef == null"
              @click="saveWidget"
              priority="priority1"
              >{{ $t('Guardar') }}</d-button
            >
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<style scoped lang="scss">
@import '../css/foundations/colors.scss';

.q-dialog__inner--maximized {
  .d-widget-type-selector {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    .sidebar {
      width: 100%;
    }

    .preview {
      display: none;
    }
  }
}
.d-widget-type-selector {
  display: flex;
  height: 80vh;
  width: 80vw;
  max-width: 80vw;
  background-color: #fff;

  .preview {
    flex-grow: 1;
    width: calc(80vw - 334px);
    max-width: calc(80vw - 334px);
    background-color: var(--grey-50);
    overflow: auto;
    display: grid;

    &.center-horizontal {
      justify-content: center;
    }
    &.center-vertical {
      align-content: center;
    }

    .widget {
      position: relative;
      margin: 20px;
    }
  }
  .sidebar {
    width: 334px;
  }
}

.d-widget-type-selector-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .d-widget-type-selector-sidebar-header {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .d-title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      color: var(--grey-700);
    }

    .d-close {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
  }
  .d-widget-type-selector-sidebar-search {
    padding: 16px;
    width: 100%;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: var(--grey-200);

    .d-widget-type-selector-sidebar-search-text {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .d-input-text {
        width: 100%;
      }
    }

    .d-widget-type-selector-sidebar-search-dimensions {
      margin-left: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 100px;
    }
  }
  .d-widget-type-selector-sidebar-result {
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
  }

  .d-widget-type-selector-sidebar-bottom {
    width: 100%;
    padding: 16px;

    .d-button {
      width: 100%;
    }
  }
}
</style>
