<script setup lang="ts">
  import { computed } from 'vue'

  const slots = defineSlots()

  const props = withDefaults(
    defineProps<{
      leftIcon?: string
      icon?: string
      disabled?: boolean
      /**
       * @deprecated Use primary, secondary, tertiary, link, selected options instead
       */
      priority?: 'priority1' | 'priority2' | 'priority3' | 'link'
      primary?: boolean
      secondary?: boolean
      tertiary?: boolean
      link?: boolean
      md?: boolean
      destructive?: boolean
      selected?: boolean
      submit?: boolean
      loading?: boolean
    }>(),
    {
      primary: true,
    },
  )

  const onlyIcon = computed(() => slots.default === undefined)

  const buttonClass = computed(() => {
    const { md, destructive, disabled, secondary, tertiary, link, selected, priority } = props

    const sizeStdClass = {
      'h-7': true,
      'px-2': true,
      'py-[5px]': true,
    }

    const sizeMdClass = {
      'h-10': true,
      'px-4': true,
      'py-2.5': true,
    }

    const sizeOnlyIconStdClass = {
      'w-[26px]': true,
      'h-[26px]': true,
      'p-[5px] ': true,
    }

    const sizeOnlyIconMdClass = {
      'h-10': true,
      'w-10': true,
      'p-[11px] ': true,
    }

    const sizeClass = onlyIcon.value ? (md ? sizeOnlyIconMdClass : sizeOnlyIconStdClass) : md ? sizeMdClass : sizeStdClass

    const primaryStdClass = {
      'bg-blue-500': !disabled,
      'text-slate-50': true,
      'hover:bg-blue-600': !disabled,
      'focus-visible:shadow-[0_0_0_4px_rgb(228,238,251)]': !disabled,
      'bg-blue-200': disabled,
    }

    const primaryDestructiveClass = {
      'bg-red-500': !disabled,
      'text-neutral-50': true,
      'hover:bg-red-600': !disabled,
      'focus-visible:shadow-[0_0_0_4px_rgb(254,228,226)]': !disabled,
      'focus-visible:bg-red-600': !disabled,
      'bg-red-200': disabled,
    }

    const primaryClass = destructive ? primaryDestructiveClass : primaryStdClass

    const secondaryStdClass = {
      'bg-white': !disabled,
      border: true,
      'border-gray-300': !disabled,
      'text-slate-700': !disabled,
      'hover:bg-gray-50': !disabled,
      'hover:text-slate-800': !disabled,
      'focus-visible:shadow-[0_0_0_4px_rgb(242,244,247)]': !disabled,
      'bg-gray-50': disabled,
      'text-gray-300': disabled,
      'border-gray-200': disabled,
    }

    const secondaryDestructiveClass = {
      'bg-white': !disabled,
      border: true,
      'border-red-300': !disabled,
      'text-red-700': !disabled,
      'hover:bg-red-50': !disabled,
      'hover:text-red-800': !disabled,
      'focus-visible:shadow-[0_0_0_4px_rgb(254,228,226)]': !disabled,
      'bg-neutral-50': disabled,
      'text-red-200': disabled,
      'border-red-200': disabled,
    }

    const secondaryClass = destructive ? secondaryDestructiveClass : secondaryStdClass

    const tertiaryStdClass = {
      'text-blue-800': !disabled,
      'hover:bg-sky-100': !disabled,
      'hover:text-blue-900': !disabled,
      'focus-visible:text-blue-500': !disabled,
      'bg-gray-50': disabled,
      'text-gray-300': disabled,
    }

    const tertiaryDestructiveClass = {
      'text-red-700': !disabled,
      'hover:bg-red-50': !disabled,
      'hover:text-red-800': !disabled,
      'focus-visible:text-red-500': !disabled,
      'text-red-200': disabled,
    }

    const tertiaryClass = destructive ? tertiaryDestructiveClass : tertiaryStdClass

    const linkClass = {
      'text-blue-600': !disabled,
      'hover:text-blue-400': !disabled,
      'hover:text-blue-900': !disabled,
      'focus-visible:text-slate-700': !disabled,
      'text-gray-400': disabled,
    }

    const selectedClass = {
      'bg-sky-100': !disabled,
      'border-blue-300': !disabled,
      'text-blue-900': !disabled,

      'hover:bg-blue-100': !disabled,
      'hover:text-blue-900': !disabled,

      'focus-visible:shadow-[0_0_0_4px_rgb(228,238,251)]': !disabled,
      'focus-visible:text-blue-900': !disabled,

      'bg-slate-50': disabled,
      'text-blue-200': disabled,
      'border-blue-200': disabled,
    }

    const _secondary = secondary || priority === 'priority2'
    const _tertiary = tertiary || priority === 'priority3'
    const _link = link || priority === 'link'

    const colorClass = selected ? selectedClass : _link ? linkClass : _tertiary ? tertiaryClass : _secondary ? secondaryClass : primaryClass

    return { ...sizeClass, ...colorClass, 'focus-visible:outline-none': true }
  })
</script>

<template>
  <button class="rounded-md justify-center items-center gap-2 flex cursor-pointer" :disabled="disabled"
          :class="buttonClass">
    <d-icon :name="leftIcon"
            :class="md ? 'd-icon-size-s' : 'd-icon-size-xs'"
            v-if="!onlyIcon" />
    <div class="font-medium"
         :class="{
        'text-xs': !md,
        'text-sm': md,
        'leading-[18px]': !md,
        'leading-tight': md,
      }"
         v-if="slots.default">
      <slot></slot>
    </div>
    <d-icon :name="icon"
            :class="{
        'd-icon-size-s': md,
        'd-icon-size-xs': !md,
      }" />
    <d-spin-icon v-if="loading" />
  </button>
</template>
