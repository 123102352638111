export const keys = {
  indicators: {
    antiguedad_inmuebles: {name: 'indicators.antiguedad_inmuebles.name', desc: 'indicators.antiguedad_inmuebles.desc',},
    compraventa_viviendas: {name: 'indicators.compraventa_viviendas.name', desc: 'indicators.compraventa_viviendas.desc',},
    compraventa_viviendas_prop: {name: 'indicators.compraventa_viviendas_prop.name', desc: 'indicators.compraventa_viviendas_prop.desc',},
    ejecuciones_hipotecarias: {name: 'indicators.ejecuciones_hipotecarias.name', desc: 'indicators.ejecuciones_hipotecarias.desc',},
    ejecuciones_hipotecarias_prop: {name: 'indicators.ejecuciones_hipotecarias_prop.name', desc: 'indicators.ejecuciones_hipotecarias_prop.desc',},
    incidencias_anuales_en_comunidades: {name: 'indicators.incidencias_anuales_en_comunidades.name', desc: 'indicators.incidencias_anuales_en_comunidades.desc',},
    ipv_general: {name: 'indicators.ipv_general.name', desc: 'indicators.ipv_general.desc',},
    ipv_vivienda_nueva: {name: 'indicators.ipv_vivienda_nueva.name', desc: 'indicators.ipv_vivienda_nueva.desc',},
    ipv_vivienda_segunda_mano: {name: 'indicators.ipv_vivienda_segunda_mano.name', desc: 'indicators.ipv_vivienda_segunda_mano.desc',},
    media_hipotecas: {name: 'indicators.media_hipotecas.name', desc: 'indicators.media_hipotecas.desc',},
    numero_de_inmuebles: {name: 'indicators.numero_de_inmuebles.name', desc: 'indicators.numero_de_inmuebles.desc',},
    numero_hipotecas: {name: 'indicators.numero_hipotecas.name', desc: 'indicators.numero_hipotecas.desc',},
    numero_hipotecas_prop: {name: 'indicators.numero_hipotecas_prop.name', desc: 'indicators.numero_hipotecas_prop.desc',},
    plazas_turisticas: {name: 'indicators.plazas_turisticas.name', desc: 'indicators.plazas_turisticas.desc',},
    precio_medio_alquiler_fc: {name: 'indicators.precio_medio_alquiler_fc.name', desc: 'indicators.precio_medio_alquiler_fc.desc',},
    precio_medio_m2_venta_fc: {name: 'indicators.precio_medio_m2_venta_fc.name', desc: 'indicators.precio_medio_m2_venta_fc.desc',},
    precio_medio_venta_fc: {name: 'indicators.precio_medio_venta_fc.name', desc: 'indicators.precio_medio_venta_fc.desc',},
    superficie_inmuebles: {name: 'indicators.superficie_inmuebles.name', desc: 'indicators.superficie_inmuebles.desc',},
    transacciones_vivienda: {name: 'indicators.transacciones_vivienda.name', desc: 'indicators.transacciones_vivienda.desc',},
    transacciones_vivienda_prop: {name: 'indicators.transacciones_vivienda_prop.name', desc: 'indicators.transacciones_vivienda_prop.desc',},
    viviendas_turisticas: {name: 'indicators.viviendas_turisticas.name', desc: 'indicators.viviendas_turisticas.desc',},
    viviendas_turisticas_prop: {name: 'indicators.viviendas_turisticas_prop.name', desc: 'indicators.viviendas_turisticas_prop.desc',},
    eficiencia_energetica: {name: 'indicators.eficiencia_energetica.name', desc: 'indicators.eficiencia_energetica.desc',},
    osm_centros_educativos: {name: 'indicators.osm_centros_educativos.name', desc: 'indicators.osm_centros_educativos.desc',},
    osm_hospitales: {name: 'indicators.osm_hospitales.name', desc: 'indicators.osm_hospitales.desc',},
    osm_supermercados: {name: 'indicators.osm_supermercados.name', desc: 'indicators.osm_supermercados.desc',},
    osm_transporte_publico_bus: {name: 'indicators.osm_transporte_publico_bus.name', desc: 'indicators.osm_transporte_publico_bus.desc',},
    osm_transporte_publico_trenes: {name: 'indicators.osm_transporte_publico_trenes.name', desc: 'indicators.osm_transporte_publico_trenes.desc',},
    edad_poblacion: {name: 'indicators.edad_poblacion.name', desc: 'indicators.edad_poblacion.desc',},
    inmigracion: {name: 'indicators.inmigracion.name', desc: 'indicators.inmigracion.desc',},
    fecundidad: {name: 'indicators.fecundidad.name', desc: 'indicators.fecundidad.desc',},
    ipc_medio: {name: 'indicators.ipc_medio.name', desc: 'indicators.ipc_medio.desc',},
    ipi_medio: {name: 'indicators.ipi_medio.name', desc: 'indicators.ipi_medio.desc',},
    mortalidad: {name: 'indicators.mortalidad.name', desc: 'indicators.mortalidad.desc',},
    poblacion: {name: 'indicators.poblacion.name', desc: 'indicators.poblacion.desc',},
    renta_media: {name: 'indicators.renta_media.name', desc: 'indicators.renta_media.desc',},
    separaciones: {name: 'indicators.separaciones.name', desc: 'indicators.separaciones.desc',},
    separaciones_prop: {name: 'indicators.separaciones_prop.name', desc: 'indicators.separaciones_prop.desc',},
    tasa_de_paro: {name: 'indicators.tasa_de_paro.name', desc: 'indicators.tasa_de_paro.desc',},
    total_prestamos: {name: 'indicators.total_prestamos.name', desc: 'indicators.total_prestamos.desc',},
    total_prestamos_prop: {name: 'indicators.total_prestamos_prop.name', desc: 'indicators.total_prestamos_prop.desc',},
    valor_medio_prestamos: {name: 'indicators.valor_medio_prestamos.name', desc: 'indicators.valor_medio_prestamos.desc',},
    precio_medio_m2_alquiler_fc:{name: 'indicators..nameprecio_medio_m2_alquiler_fc', desc: 'indicators.precio_medio_m2_alquiler_fc.desc',},
  },
  categories: {
    socioeconomic: 'categories.socioeconomic',
    quality: 'categories.quality',
    inmo: 'categories.inmo',
  }
}
