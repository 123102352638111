import es from './locales/es.json'
import ca from './locales/ca.json'
import * as benchmark from '../pages/BM/translations'
import * as calculadora from '../pages/Calculadoras/translations'
import * as mizona from '../pages/MiZona/translations'

export default {
  es: {
    ...es,
    ...benchmark.es,
    ...calculadora.es,
    ...mizona.es
  },
  ca: {
    ...ca,
    ...benchmark.ca,
    ...calculadora.ca
  }
}
