/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoUtilities from 'src/utilities/crypt'
import { LocalStorage, Platform } from 'quasar'
import Cookies from 'js-cookie'
const KS = 'j@sZ)&^-Se/GlD1|r$S).g=^}nY7`e|-")X[V:m:S*}0Hh{KnP6M1shiav'

export const localStorage = {
  getItem: (key: string) => {
    let result: any = null
    const stateCrypt = LocalStorage.getItem(key)?.toString()
    if (stateCrypt != null) {
      if (stateCrypt != null && stateCrypt != undefined) {
        const decrypt = CryptoUtilities.decrypt(stateCrypt, KS)

        result = JSON.parse(decrypt)
      }
    }
    return result
  },
  setItem: (key: string, state: any) => {
    LocalStorage.set(key, CryptoUtilities.encrypt(JSON.stringify(state), KS))
  },
  removeItem: (key: string) => {
    LocalStorage.remove(key)
  },
}

export const cookieStorage = {
  getItem: (key: string) => {
    let result: any = null
    const stateCrypt = Cookies.get(key)

    if (stateCrypt != null) {
      if (stateCrypt != null && stateCrypt != undefined) {
        const decrypt = CryptoUtilities.decrypt(stateCrypt, KS)

        result = JSON.parse(decrypt)
      }
    }
    return result
  },
  setItem: (key: string, state: any) => {
    Cookies.set(key, CryptoUtilities.encrypt(JSON.stringify(state), KS), {
      expires: state.recordar == true && state.tipoAcceso == 2 ? 182 : undefined,
      secure: true,
      samesite: Platform.is.ios == true ? 'None' : 'Strict',
    })
  },
}
