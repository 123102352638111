<script setup lang="ts">
//import DWidget from 'src/components/DWidget.vue'
import ATooltip from './ATooltip.vue'
import { Article } from 'src/model/response/news.models'
//import { ref } from 'vue'

const props = withDefaults(defineProps<{ article: Article; textOverlap?: boolean; imageRounded?: boolean }>(), { textOverlap: false, imageRounded: false })

const defaultImage = '/img/news-default.png'
</script>

<template>
  <div :class="{ 'd-news-article-text-overlap': props.textOverlap, 'd-news-article': !props.textOverlap }">
    <a
      :href="props.article.link"
      target="_blank"
    >
      <q-img
        class="d-news-article-image"
        :class="{ rounded: imageRounded }"
        :src="props.article.imagen ?? defaultImage"
        fit="cover"
      >
      </q-img>
      <div class="d-news-article-info">
        <div
          class="d-news-article-info-background"
          :class="{ rounded: imageRounded }"
        ></div>
        <template v-if="props.textOverlap">
          <div class="d-news-article-info-origin">{{ props.article.origen }}:</div>
          <div class="d-news-article-info-title">
            {{ props.article.titulo }}
          </div>
        </template>
        <template v-else>
          <span class="d-news-article-info-origin">{{ props.article.origen }}:</span> <span class="d-news-article-info-title"> {{ props.article.titulo }}</span>
        </template>
      </div>
      <a-tooltip :delay="500">
        {{ props.article.origen }}:<br />
        {{ props.article.titulo }}
      </a-tooltip>
    </a>
  </div>
</template>

<style scoped lang="scss">
@import '../css/foundations/fonts.scss';
@import '../css/foundations/colors.scss';

.d-news-article-image.rounded {
  border-radius: 4px;
}

.d-news-article-info-background.rounded {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.d-news-article {
  position: relative;
  width: 100%;

  a {
    text-decoration: none;
  }

  .d-news-article-image {
    height: 64px;
    width: 100%;
  }

  .d-news-article-info {
    height: 52px;
    width: 100%;
    color: var(--grey-700);
    text-align: left;
    padding-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  .d-news-article-info-origin {
    @extend .d-text-xs-semibold;
    text-decoration: none;
  }
  .d-news-article-info-title {
    @extend .d-text-xs-regular;
    text-decoration: none;
  }
}

.d-news-article-text-overlap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .d-news-article-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .d-news-article-info {
    position: absolute;
    height: 52px;
    width: 100%;
    left: 0;
    bottom: 0;
    color: var(--grey-25);
    text-align: left;
    padding: 8px;
  }
  .d-news-article-info-background {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.75;
  }

  .d-news-article-info-origin {
    @extend .d-text-xs-semibold;
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .d-news-article-info-title {
    @extend .d-text-xs-regular;
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
