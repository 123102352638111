import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { setLanguage } from 'src/boot/i18n'
import { localStorage } from './storage'

export const useConfigStore = defineStore('config', {
  state: () => {
    return {
      deviceId: uuidv4(),
      idioma: 'es',
      drawerMini: true,
      cookieConsent: { respondido: false, analytics: false },
    }
  },
  actions: {
    actualizarCookieConsent(respondido: boolean, analytics: boolean) {
      this.$patch((state) => {
        state.cookieConsent.respondido = respondido
        state.cookieConsent.analytics = analytics
      })
    },
    setIdioma(idioma: string) {
      this.idioma = idioma
      setLanguage(this.idioma)
    },
  },
  persist: {
    key: 'preferences',
    storage: localStorage,
    afterRestore: (context) => {
      setLanguage(context.store.idioma)
    },
  },
})
