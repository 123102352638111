import { KPIsAgrupadosResponse, KPIsResponse } from 'src/model/response/metrics.models'
import { api } from '../boot/axios'

export const metricsService = {
  async getKPIs(): Promise<KPIsResponse> {
    return (await api.get<KPIsResponse>('/metrics/kpi')).data
  },
  async getKPIsAgrupados(): Promise<KPIsAgrupadosResponse> {
    return (await api.get<KPIsAgrupadosResponse>('/metrics/KPIAgrupado')).data
  },
}
