import { keys } from './keys';

export const es = {
  [keys.PH.Titulo]: 'Comunidades',

  [keys.PH.Cartera.Titulo]: 'Cartera',
  [keys.PH.FacturacionTotal.Titulo]: 'Facturación (total)',
  [keys.PH.FacturacionCartera.Titulo]: 'Facturación (cartera)',
  [keys.PH.Eficiencia.Titulo]: 'Eficiencia',
  [keys.PH.Impagos.Titulo]: 'Impagos',

  [keys.PH.Cartera.Descripcion[0]]: 'El informe muestra el volumen de negocios de tu empresa en Propiedad Horizontal, permitiéndote compararlo con las otras empresas del sector. Una alta puntuación refleja un alto volumen de cartera. Puedes evaluar tus estrategias de expansión respecto al mercado y ver si estas teniendo una expansión adecuada. Los valores representan una puntuación teórica basadas en empresas del sector. El volumen en Propiedad Horizontal es un indicador clave de éxito en gestión inmobiliaria. Una cartera grande puede traer mayores ingresos y oportunidades, siempre que la administración sea eficiente. Este análisis es esencial para el crecimiento y eficacia de tu empresa en el sector.',

  [keys.PH.FacturacionTotal.Descripcion[0]]: 'Esta sección del informe refleja el volumen total de facturación en Propiedad Horizontal de tu empresa, facilitando comparativas en áreas de facturación (honorarios o rappels) frente al promedio del sector. Además, valida objetivamente las tasas a aplicar a tus proveedores.',
  [keys.PH.FacturacionTotal.Descripcion[1]]: 'También se puede observar las principales áreas de facturación de tu empresa para detectar anomalías y promover un crecimiento empresarial sostenible y positivo a largo plazo.',
  [keys.PH.FacturacionTotal.Descripcion[2]]: 'Los valores de "score" en esta sección indican una puntuación teórica basada en empresas similares, evaluando si tu gestión de facturación es eficiente y efectiva en comparación con el sector.',

  [keys.PH.FacturacionCartera.Descripcion[0]]: 'En esta sección, se desglosa el volumen de facturación por elemento y tipo en la administración de comunidades. Dos gráficos evalúan objetivamente los resultados en cada elemento, con un valor más alto indicando mayor facturación por elemento.',
  [keys.PH.FacturacionCartera.Descripcion[1]]: 'Por otro lado, los otros dos gráficos muestran la facturación directa (honorarios) y la indirecta (obras) en términos totales. Esto revela la variabilidad de ingresos y dependencia de proveedores.',
  [keys.PH.FacturacionCartera.Descripcion[2]]: 'Los valores de "score" se basan en puntuaciones teóricas de empresas similares. Esto nos referencia para evaluar el rendimiento comparado con otras del sector.',

  [keys.PH.Eficiencia.Descripcion[0]]: '"El informe se centra en la eficiencia operativa de tu empresa en Propiedad Horizontal. Esta sección proporciona percepciones sobre la eficacia de las actividades clave en este ámbito.',
  [keys.PH.Eficiencia.Descripcion[1]]: 'El informe se basa en dos aspectos cruciales para evaluar la eficiencia en Propiedad Horizontal: gestión de incidencias y ejecución de presupuestos. La gestión de incidencias evalúa cómo se manejan problemas en comunidades de propietarios, vital para la satisfacción y el funcionamiento adecuado. La ejecución de presupuestos se centra en el uso de recursos para mantenimiento.',
  [keys.PH.Eficiencia.Descripcion[2]]: 'Valores altos en los ratios indican problemas o falta de medidas efectivas. Asegurar una gestión de incidencias y ejecución de presupuestos sólidas es crucial para la satisfacción de propietarios y el éxito operativo.',

  [keys.PH.Impagos.Descripcion[0]]: 'El informe ofrece un panorama completo del impago en clientes y comunidades en la propiedad horizontal.',
  [keys.PH.Impagos.Descripcion[1]]: 'Los dos primeros histogramas comparan tu impago total y antigüedad con el sector, permitiendo evaluar la similitud. Herramienta útil para perspectiva comparativa y posición en el mercado en impagos.',
  [keys.PH.Impagos.Descripcion[2]]: 'Los dos histogramas siguientes exhiben la distribución del impago entre comunidades que administras, mostrando porcentajes con saldos negativos. Esto ayuda a entender la magnitud del impago y su tendencia, esencial para reducirlo y conocer el promedio sectorial. Analizar la eficacia de tu gestión también es posible.',
  [keys.PH.Impagos.Descripcion[3]]: 'En resumen, el informe detalla y compara impagos en propiedad horizontal, con histogramas para tomar decisiones informadas y mejorar comunidades financieramente.',

  [keys.PH.PH1.cabecera]: 'Comunidades',
  [keys.PH.PH2.cabecera]: 'Departamentos',
  [keys.PH.RPH1.cabecera]: 'Departamentos por comunidad',
  [keys.PH.PH37.cabecera]: 'Extensión cartera',
  [keys.PH.RPH21.cabecera]: 'Comunidades por administrador',

  [keys.PH.PH36.cabecera]: 'Facturación total',
  [keys.PH.PH4.cabecera]: 'Honorarios totales',
  [keys.PH.PH6.cabecera]: 'Rápeles totales',
  [keys.PH.RPH9.cabecera]: 'Tasa rápel proveedores',
  [keys.PH.PH5.cabecera]: 'Facturación proveedores',

  [keys.PH.RPH3.cabecera]: 'Honorarios por comunidad',
  [keys.PH.RPH8.cabecera]: 'Honorarios por departamento',
  [keys.PH.RPH30.cabecera]: 'Tasa de Honorario por Rápel',
  [keys.PH.PH27.cabecera]: 'Facturas a clientes',
  [keys.PH.RPH4.cabecera]: 'Rápel por comunidad',

  [keys.PH.RPH12.cabecera]: 'Cumplimiento de presupuesto',
  [keys.PH.RPH31.cabecera]: 'Presupuesto extraordinario por comunidad',
  [keys.PH.RPH6.cabecera]: 'Incidencias por comunidad',
  [keys.PH.RPH23.cabecera]: 'Tasa de comunidades con crisis financiera',
  [keys.PH.RPH27.cabecera]: 'Departamentos por administrador',

  [keys.PH.PH3.cabecera]: 'Deuda clientes',
  [keys.PH.RPH32.cabecera]: 'Antigüedad de la deuda',
  [keys.PH.RPH18.cabecera]: 'Tasa de Comunidades con deficit',
  [keys.PH.RPH19.cabecera]: 'Tasa de comunidades morosas',
  [keys.PH.RPH15.cabecera]: 'Tasa de impago',

  [keys.PH.PH1.tooltip]: 'Número total de Comunidades gestionadas por tu empresa',
  [keys.PH.PH2.tooltip]: 'Número total de departamentos gestionados por tu empresa',
  [keys.PH.RPH1.tooltip]: 'Suma total de departamentos gestionados dividido entre comunidades totales gestionadas',
  [keys.PH.PH37.tooltip]: 'Cantidad de códigos postales diferentes donde esta ubicada tu cartera',
  [keys.PH.RPH21.tooltip]: 'Promedio de comunidades gestionada por un administrador en tu empresa',

  [keys.PH.PH36.tooltip]: 'Suma de Honorarios  más rappeles facturados del mes anterior',
  [keys.PH.PH4.tooltip]: 'Suma de honorarios facturados del mes anterior',
  [keys.PH.PH6.tooltip]: 'Suma de rápeles facturados en el mes anterior',
  [keys.PH.RPH9.tooltip]: 'Rápeles totales dividido entre la facturación a proveedores en el mes anterior',
  [keys.PH.PH5.tooltip]: 'Suma total de la facturación a preveedores en el mes anteior',

  [keys.PH.RPH3.tooltip]: 'Suma de Honorarios totales en los últimos 12 meses entre las comunidades gestionadas',
  [keys.PH.RPH8.tooltip]: 'Suma de Honorarios totales en los últimos 12 meses entre los departamentos gestionados',
  [keys.PH.RPH30.tooltip]: 'Suma de Honorarios entre la suma de Rápeles del mes anterior',
  [keys.PH.PH27.tooltip]: 'Número de Facturas emitidas a cliente en el mes anterior',
  [keys.PH.RPH4.tooltip]: 'Suma de rápeles facturados en el mes anterior entre el total de comunidades gestionadas',

  [keys.PH.RPH12.tooltip]: 'Suma de Importe ordinario ejecutado entre la suma de importe presupuestado en lo que va de año',
  [keys.PH.RPH31.tooltip]: 'Suma de presupuesto extraordinario presupuesto entre el total de comunidades gestionadas',
  [keys.PH.RPH6.tooltip]: 'Incidencias abiertas en el mes anterior dividido entre el total de comunidades gestionadas',
  [keys.PH.RPH23.tooltip]: 'Comunidades con sobrejecución de presupuesto dividido entre las comunidades con saldo negativo en el mes anterior',
  [keys.PH.RPH27.tooltip]: 'Promedio de departamentos gestionado por un administrador',

  [keys.PH.PH3.tooltip]: 'Suma de la deuda total de los clientes en el mes anterior',
  [keys.PH.RPH32.tooltip]: 'Suma del total de días de las facturas impagadas en el mes anterior',
  [keys.PH.RPH18.tooltip]: 'Comunidades con saldo negativo en el mes anterior entre el total de comunidades gestionadas',
  [keys.PH.RPH19.tooltip]: 'Comunidades con deuda en el ems anterior dividido entre el total de de comunidades gestionadas',
  [keys.PH.RPH15.tooltip]: 'Suma del impago total del mes anterior dividido entre la suma de honorarios y rápeles del mes anterior',

  [keys.PV.Titulo]: 'Fincas',

  [keys.PV.Cartera.Titulo]: 'Cartera',
  [keys.PV.FacturacionTotal.Titulo]: 'Facturación (total)',
  [keys.PV.FacturacionCartera.Titulo]: 'Facturación (cartera)',
  [keys.PV.Eficiencia.Titulo]: 'Eficiencia',
  [keys.PV.Impagos.Titulo]: 'Impagos',

  [keys.PV.Cartera.Descripcion[0]]: 'El Informe de Cartera en Propiedad Vertical brinda una visión global de tu volumen de cartera comparado con otras administraciones de fincas. Una alta puntuación aquí señala que superas la media del sector. Evalúa la relación entre fincas gestionadas y departamentos en cada una, crucial para rentabilidad.',
  [keys.PV.Cartera.Descripcion[1]]: 'Analiza también la distribución geográfica y diversidad en diferentes regiones, revelando cómo influyen tus estrategias y acciones en tu posición dentro del rango TAAF. Valida decisiones y enfoques diarios.',
  [keys.PV.Cartera.Descripcion[2]]: 'Esta sección es esencial para evaluar el rendimiento y tomar decisiones basadas en datos. Al entender tu posición respecto a otros, puedes maximizar rentabilidad y mejorar servicios.',

  [keys.PV.FacturacionTotal.Descripcion[0]]: 'El Informe de Facturación en Propiedad Vertical muestra el total facturado por tu empresa en este sector. Aquí, compararás el desempeño en áreas como honorarios o rappels con el promedio del sector. Identifica tus fortalezas y áreas de mejora para un enfoque estratégico. Valida tus tasas justamente con proveedores y mantén equilibrio.',
  [keys.PV.FacturacionTotal.Descripcion[1]]: 'Este informe destaca desviaciones significativas en áreas clave, permitiendo tomar acciones correctivas y fortalecer sectores necesitados. Así, lograrás crecimiento sostenible y posición destacada en Propiedad Vertical.',
  [keys.PV.FacturacionTotal.Descripcion[2]]: 'Las puntuaciones mostradas reflejan comparativas teóricas con competidores, brindando medida objetiva sobre tu eficiencia en gestión de facturación. Evalúa si tu enfoque es efectivo comparado a otros en el ámbito.',

  [keys.PV.FacturacionCartera.Descripcion[0]]: 'El Informe de Facturación en el Área de Propiedad Vertical muestra el volumen de facturación de cada entidad. Los primeros dos histogramas permiten evaluar objetivamente los resultados de cada propietario o entidad. Un valor más alto indica mayor facturación en comparación con otros, brindando información valiosa para identificar áreas clave y aquellas que necesitan atención.',
  [keys.PV.FacturacionCartera.Descripcion[1]]: 'El otro histograma representa la facturación directa (honorarios) y la indirecta (obras) en términos totales. Comprenderás la distribución y variabilidad de los ingresos, evaluando la dependencia de proveedores y la diversificación de los ingresos. Analizar estos datos revela patrones y tendencias significativas, apoyando decisiones financieras.',

  [keys.PV.Eficiencia.Descripcion[0]]: 'En esta sección del informe, evaluamos la eficiencia operativa de tu empresa en Propiedad Vertical. Aquí encontrarás información clave para optimizar tus actividades en este campo.',
  [keys.PV.Eficiencia.Descripcion[1]]: 'El informe se centra en dos aspectos fundamentales: departamentos vacíos y rotación de contratos. Gestionar los departamentos vacantes es crucial para maximizar ingresos, ya que implica activos inactivos. Analiza por qué tienes estas entidades sin contratos y busca soluciones efectivas. Calcular la rotación de contratos permite evaluar tu eficiencia en la gestión de propiedades.',
  [keys.PV.Eficiencia.Descripcion[2]]: 'Valores altos en estos indicadores pueden indicar propiedades problemáticas o falta de medidas para optimizar beneficios. Es crucial implementar una gestión de recontratación y estrategias basadas en estos datos. Si la rotación es alta, asegúrate de que la facturación compense tiempo y recursos invertidos en nuevos contratos.',

  [keys.PV.Impagos.Descripcion[0]]: 'Este informe proporciona un detallado panorama sobre los impagos de clientes en el ámbito de la propiedad vertical, desglosados por entidades. Incluye histogramas informativos acerca de los impagos, su distribución entre entidades y su relación con rentas o fianzas.',
  [keys.PV.Impagos.Descripcion[1]]: 'Los primeros histogramas muestran la cantidad total de impagos y su antigüedad. Esto ayuda a entender el desempeño en términos de impagos y la posición relativa en el sector.',
  [keys.PV.Impagos.Descripcion[2]]: 'Los siguientes histogramas exponen la distribución de impagos entre tus entidades y su relación con rentas o fianzas. Esto es esencial para entender a los clientes morosos y comparar con el sector inmobiliario. Utiliza estos datos para crear estrategias que reduzcan los impagos y mejoren el rendimiento. También puedes analizar tendencias para anticipar desafíos.',

  [keys.PV.PV1.cabecera]: 'Fincas',
  [keys.PV.PV2.cabecera]: 'Departamentos',
  [keys.PV.RPV1.cabecera]: 'Departamentos/finca',
  [keys.PV.PV37.cabecera]: 'Extensión cartera',
  [keys.PV.RPV29.cabecera]: 'Departamentos por administrador',

  [keys.PV.PV38.cabecera]: 'Facturación total',
  [keys.PV.PV4.cabecera]: 'Honorarios totales',
  [keys.PV.PV6.cabecera]: 'Rápeles totales',
  [keys.PV.RPV8.cabecera]: 'Tasa rápel proveedores',
  [keys.PV.PV28.cabecera]:	'Facturación proveedores',

  [keys.PV.RPV37.cabecera]: 'Facturación por propietario',
  [keys.PV.RPV7.cabecera]: 'Honorarios por departamento',
  [keys.PV.RPV38.cabecera]: 'Tasa de honorarios por rápel',
  [keys.PV.PV27.cabecera]:	'Facturas a clientes',
  [keys.PV.RPV4.cabecera]: 'Rápel por finca',

  [keys.PV.RPV36.cabecera]: 'Tasa de departamentos sin contrato',
  [keys.PV.RPV16.cabecera]: 'Tasa de desocupación a largo plazo',
  [keys.PV.PV26.cabecera]: 'Tasa de desocupación a corto plazo',
  [keys.PV.PV41.cabecera]: 'Duración de contratos',
  [keys.PV.RPV15.cabecera]: 'Tasa global de ocupación',

  [keys.PV.PV3.cabecera]: 'Deuda clientes',
  [keys.PV.PV39.cabecera]: 'Antigüedad de la deuda',
  [keys.PV.RPV23.cabecera]: 'Tasa de morosidad',
  [keys.PV.RPV20.cabecera]: 'Tasa de deuda entre renta mensual',
  [keys.PV.RPV24.cabecera]: 'Tasa de renta cubierta por garantías y avales',

  [keys.PV.RPV21.cabecera]: 'Rotación de contratos',

  [keys.PV.PV1.tooltip]: 'Número total de fincas que gestionanadas en tu empresa',
  [keys.PV.PV2.tooltip]: 'Número total de departamentos que se gestionan en tu empresa',
  [keys.PV.RPV1.tooltip]: 'Suma total de departamentos entre el total de fincas que se gestionan en tu empresa',
  [keys.PV.PV37.tooltip]: 'Cantidad de códigos postales diferentes donde esta ubicada tu cartera',
  [keys.PV.RPV29.tooltip]: 'Promedio de departamentos gestionada por un administrador en tu empresa',

  [keys.PV.PV38.tooltip]: 'Suma Honorarios más rápeles facturados en el mes anterior',
  [keys.PV.PV4.tooltip]: 'Suma de honorarios facturados en el mes anterior',
  [keys.PV.PV6.tooltip]: 'Suma de rápeles totales facturados en el mes anterior',
  [keys.PV.RPV8.tooltip]: 'Rápeles totales dividido entre la facturación a proveedores en el mes anterior',
  [keys.PV.PV28.tooltip]:	'Suma total de la facturación a preveedores en el mes anteior',

  [keys.PV.RPV37.tooltip]: 'Suma de honorarios más rápeles del mes anterior dividio entre el total de propietarios',
  [keys.PV.RPV7.tooltip]: 'Suma honorarios del mes anterior entre el total de departamentos gestionados',
  [keys.PV.RPV38.tooltip]: 'Suma de Honorarios entre la suma de Rápeles del mes anterior',
  [keys.PV.PV27.tooltip]:	'Número de Facturas emitidas a cliente en el mes anterior',
  [keys.PV.RPV4.tooltip]: 'Suma de rápeles facturados en el mes anterior entre el total de Fincas gestionadas',

  [keys.PV.RPV36.tooltip]: 'Departamentos sin contrato del mes anterior entre el total de departamentos gestionados',
  [keys.PV.RPV16.tooltip]: 'Departamentos sin contratos a largo plazo(más de 6 meses) del mes anterior entre el total dedepartamentos',
  [keys.PV.PV26.tooltip]: 'Departamentos sin contrato a corto plazo(6 meses) del mes anterior entre departamentos totales',
  [keys.PV.PV41.tooltip]: 'Promedio de duración de contratos en el ultimo mes',
  [keys.PV.RPV15.tooltip]: 'Cotratos activos del ultimos mes entre departamentos totales',

  [keys.PV.PV3.tooltip]: 'Suma de la deuda total de los clientes en el mes anterior',
  [keys.PV.PV39.tooltip]: 'Suma del total de días de las facturas impagadas en el mes anterior',
  [keys.PV.RPV23.tooltip]: 'Inquilinos morosos del mes anterior entre el total de inquilinos',
  [keys.PV.RPV20.tooltip]: 'Deuda total del mes anterior dividido entre la la suma de la renta total mensual',
  [keys.PV.RPV24.tooltip]: 'Suma de garantías y avales totales dividido entre la renta total mensual(foto del mes anterior)',

  [keys.PV.RPV21.tooltip]: 'Rotación de contratos',

  [keys.GEN.Titulo]: 'Global',

  [keys.GEN.DesarrolloNegocio.Titulo]: 'Desarrollo de negocio',
  [keys.GEN.Facturacion.Titulo]: 'Facturación',
  [keys.GEN.Rentabilidad.Titulo]: 'Rentabilidad',
  [keys.GEN.ExcelenciaOperativa.Titulo]: 'Excelencia operativa',

  [keys.GEN.DesarrolloNegocio.Descripcion[0]]: 'Este informe detalla el progreso de tu negocio en todas sus áreas, ofreciendo una visión completa. El objetivo es brindar datos sobre tus clientes, tanto en Propiedad Horizontal como en Propiedad Vertical, y analizar las altas y bajas de clientes. También se destaca la cantidad de clientes con múltiples servicios.',
  [keys.GEN.DesarrolloNegocio.Descripcion[1]]: 'El primer gráfico, "Altas de Clientes", muestra cómo evolucionan en comparación con competidores, evaluando el ritmo de tu empresa en el mercado.',
  [keys.GEN.DesarrolloNegocio.Descripcion[2]]: 'Las siguientes gráficas exponen la rotación de tu cartera en Propiedad Horizontal y Vertical, para determinar si es más rápida o lenta que la competencia. Además, se evalúa la relación entre altas y bajas en la tercera gráfica, midiendo la estabilidad. Estos gráficos ayudan a medir tus estrategias y la posición en el mercado.',
  [keys.GEN.DesarrolloNegocio.Descripcion[3]]: 'Finalmente, se destaca la importancia de clientes con múltiples servicios para la estabilidad del negocio.',

  [keys.GEN.Facturacion.Descripcion[0]]: 'En esta sección, detallamos la facturación total de tu empresa en varias áreas, diferenciando entre la facturación directa de clientes y la proveniente de proveedores. El primer gráfico muestra el total facturado por clientes, permitiendo comparación sectorial. El siguiente refleja las comisiones de proveedores, también en comparación. Luego, gráficos representan el porcentaje de facturación en distintas áreas: primero, la Propiedad Horizontal (PH), luego la Propiedad Vertical (PV). Por último, se visualiza el porcentaje de gastos generales respecto a la facturación. Estos ratios permiten compararte con promedios del sector, identificando fortalezas y áreas subfacturadas. En resumen, obtienes visión clara de ingresos en varias áreas y comparación sectorial, para realzar fortalezas y mejorar.',

  [keys.GEN.Rentabilidad.Descripcion[0]]: 'En esta sección, evaluamos aspectos de tu empresa que determinan su rentabilidad. Los gráficos se dividen en tres temas principales. Primero, analizamos ratios de facturación para ver la facturación total en relación a clientes activos y a tu cartera, comparándolos con otras empresas del sector. Luego, calculamos lo mismo, pero para la deuda de tu empresa, viendo cómo se sitúan respecto a la media del sector. Finalmente, presentamos un valor de rentabilidad personal, el valor de cartera en relación a gestores, que indica la definición de procesos en tu empresa. Así, evaluarás y analizarás la rentabilidad desde distintas perspectivas, tomando decisiones informadas para mejorarla en áreas específicas.',

  [keys.GEN.ExcelenciaOperativa.Descripcion[0]]: 'En la sección de excelencia operativa, nuestro enfoque radica en fortalecer la información vinculada a las interacciones entre los trabajadores y los clientes en el ámbito de la administración de fincas. El primer gráfico ilustra el ratio de gestiones por cliente activo, permitiendo su comparación con empresas similares para evaluar la adecuación de las gestiones en tu compañía. Este equilibrio es vital para asegurar una gestión de clientes efectiva y satisfactoria. El gráfico subsiguiente representa las incidencias promedio generadas por entidad. Compararlo con otras empresas revela la eficacia en la gestión. Una menor cantidad de incidencias sugiere mejor satisfacción del cliente. El tercer gráfico muestra comunicaciones emitidas por cliente, esencial para mantener relaciones sólidas. El cuarto gráfico refleja diversificación en pagos. Por último, el total de incidencias pendientes destaca la importancia de abordarlas a tiempo para mantener operaciones eficientes y clientes satisfechos.',

  [keys.GEN.RGEN13.cabecera]: 'Tasa de clientes dados de alta',
  [keys.GEN.RGEN15.cabecera]: 'Tasa cartera cruzada PH/PV',
  [keys.GEN.RGEN16.cabecera]: 'Tasa cartera cruzada PV/PH',
  [keys.GEN.RGEN18.cabecera]: 'Volatilidad clientes',
  [keys.GEN.RGEN12.cabecera]: 'Tasa de venta cruzada',

  [keys.GEN.GEN1.cabecera]: 'Facturación clientes',
  [keys.GEN.GEN4.cabecera]: 'Comisión proveedores',
  [keys.GEN.RGEN7.cabecera]: 'Facturación PH / Total',
  [keys.GEN.RGEN8.cabecera]: 'Facturación PV / Total',
  [keys.GEN.RGEN9.cabecera]: 'Facturación Gen / Total',

  [keys.GEN.RGEN32.cabecera]: 'Tasa de facturación por cliente',
  [keys.GEN.RGEN33.cabecera]: 'Tasa de facturación por cartera',
  [keys.GEN.RGEN27.cabecera]: 'Tasa de deuda por cliente',
  [keys.GEN.RGEN28.cabecera]: 'Tasa de deuda por cartera',
  [keys.GEN.RGEN34.cabecera]: 'Entidades por gestores',

  [keys.GEN.RGEN23.cabecera]: 'Gestiones por cliente',
  [keys.GEN.RGEN19.cabecera]: 'Tasa de incidencias por cartera',
  [keys.GEN.RGEN22.cabecera]: 'Tasa de comunicaciones por cliente',
  [keys.GEN.RGEN30.cabecera]: 'Facturas emitidas por cliente',
  [keys.GEN.GEN29.cabecera]: 'Incidencias pendientes',

  [keys.GEN.RGEN13.tooltip]: 'Total de clientes en alta dividido entre el total de clientes(fotos mes anterior)',
  [keys.GEN.RGEN15.tooltip]: 'Suma de cartera PH entre Suma de Cartera PV(foto mes anterior)',
  [keys.GEN.RGEN16.tooltip]: 'Suma de cartera PV entre Suma de Cartera PH(foto mes anterior)',
  [keys.GEN.RGEN18.tooltip]: 'Promedio de duración de un cliente en tu empresa(foto mes anterior)',
  [keys.GEN.RGEN12.tooltip]: 'Suma de clientes con todos los servicios contratados entre el total de clientes(foto mes anterior)',

  [keys.GEN.GEN1.tooltip]: 'Suma del importe total facturado a clientes del mes anterior',
  [keys.GEN.GEN4.tooltip]: 'Suma del importe total de comisión obtenido de proceedores del mes anterior',
  [keys.GEN.RGEN7.tooltip]: 'Suma de la facturación a clientes en PH del mes anterior entre la facturación total de tu empresa del mes anterior',
  [keys.GEN.RGEN8.tooltip]: 'Suma de la facturación a clientes en PV del mes anterior entre la facturación total de tu empresa del mes anterior',
  [keys.GEN.RGEN9.tooltip]: 'Suma de la facturación a clientes en que no son de PH o PV del mes anterior entre la facturación total de tu empresa del mes anterior',

  [keys.GEN.RGEN32.tooltip]: 'Facturación total dividio entre el total de clientes activos(foto del mes anterior)',
  [keys.GEN.RGEN33.tooltip]: 'Facturación total dividido entre el total de tu cartera(foto mes anterior)',
  [keys.GEN.RGEN27.tooltip]: 'Deuda total de tu empresa entre el total de clientes activos(foto mes anterior)',
  [keys.GEN.RGEN28.tooltip]: 'Deuda total de tu empresa dividido por el total de tu cartera(foto mes anterior)',
  [keys.GEN.RGEN34.tooltip]: 'Total de tu cartera dividido entre todos tus gestores de la empresa(foto mes anterior)',

  [keys.GEN.RGEN23.tooltip]: 'Gestiones realizadas el mes anterior entre los clientes activos',
  [keys.GEN.RGEN19.tooltip]: 'Incidencias abiertas el mes anterior divido entre la cartera total',
  [keys.GEN.RGEN22.tooltip]: 'Número de comunicaciones realizadas el mes anterior por cliente activo',
  [keys.GEN.RGEN30.tooltip]: 'Número de facturas emitidas dividido entre el total de clientes activos(foto mes anterior)',
  [keys.GEN.GEN29.tooltip]: 'Número de incidencias pedinetes al cerrar el mes anterior'

}
