<script setup lang="ts">
import { useTimestamp } from '@vueuse/core'
import DWidget from 'src/components/DWidget.vue'
import { computed } from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

const timestamp = useTimestamp({ offset: 0 })

dayjs.locale('es')

const time = computed(() => dayjs(timestamp.value).format('h:mm'))
const date = computed(() => dayjs(timestamp.value).format('dddd, D MMM'))
</script>

<template>
  <d-widget>
    <template v-slot:body>
      <d-icon name="schedule" />
      <div class="body">
        <div class="d-display-md-semibold text-blue900">
          {{ time }}
        </div>
        <div class="d-text-sm-medium text-grey500">{{ date }}</div>
      </div>
    </template>
  </d-widget>
</template>

<style scoped lang="scss">
.d-widget {
  .d-icon {
    font-size: var(--icon-size-l);
    color: var(--grey-400);
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .d-widget-body {
    display: flex;
    .body {
      color: var(--blue-900);
      display: flex;
      flex-direction: column;
      margin: auto;
    }
  }
}
</style>
