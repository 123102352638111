import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'

const i18n = createI18n({
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true,
  messages,
  legacy: false
})

export function setLanguage(language: string) {
  if (language == 'es' || language == 'ca') {
    i18n.global.locale.value = language
  }
}

export function useI18n() {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { t, te, tm, rt, d, n, ...globalApi } = i18n.global

  return {
    t: t.bind(i18n),
    te: te.bind(i18n),
    tm: tm.bind(i18n),
    rt: rt.bind(i18n),
    d: d.bind(i18n),
    n: n.bind(i18n),
    ...globalApi
  }
}

export default boot(({ app }) => {
  // Tell app to use the I18n instance
  app.use(i18n)
})
