<script setup lang="ts">
import DWidget from 'src/components/DWidget.vue'
import { useKPIs } from 'src/composables/kpis'
import { computed } from 'vue'
import ATooltip from 'src/components/ATooltip.vue'
import { formatNumber } from 'driving01-charts'

const props = defineProps<{ kpi: string }>()

const { counterKpi, kpisLoading } = useKPIs('year', -1)

const kpi = computed(() => counterKpi.value(props.kpi))
</script>

<template>
  <d-widget
    justify-header="space-between"
    :loading="kpisLoading"
  >
    <template
      v-slot:header
      v-if="kpi"
    >
      <d-icon name="fmd_bad" />
      <span class="d-text-xs-semibold text-blue600 d-modulo">{{ kpi.id?.match(/[a-zA-Z]+/)?.at(0) +
        (kpi.id.includes('OPERATIVO') ? ' ' + $t('Administrador') : '') }} </span>
    </template>
    <template
      v-slot:body
      v-if="kpi"
    >
      <div class="body">
        <div class="d-display-xs-semibold items-center">
          <span
            :class="`${kpi.valor ?? 0 <= (kpi.contraValor ?? 0) ? 'text-' + kpi.color + '400' : 'text-' + kpi.color + '400'}`"
          >{{
            formatNumber(kpi.valor ?? 0, {
              formatOptions: {
                useGrouping: true,
                notation: (kpi.valor ?? 0) > 99999 ? 'compact' : 'standard',
                maximumFractionDigits: 0
              }
            })
          }}</span>
          <span
            class="text-grey500"
            v-if="kpi.tipoKPI == 2"
          >
            / {{
              formatNumber(kpi.contraValor ?? 0, {
                formatOptions: {
                  useGrouping: true,
                  notation: (kpi.contraValor ?? 0) > 99999 ? 'compact' : 'standard',
                  maximumFractionDigits: 0
                }
              })
            }}</span>
        </div>
        <div class="d-text-sm-medium text-grey500 kpi-label ellipsis">
          {{ kpi.indicador
          }}<a-tooltip
            :content="kpi.indicador"
            v-if="kpi.indicador"
            :delay="500"
          ></a-tooltip>
        </div>
      </div>
    </template>
  </d-widget>
</template>

<style scoped lang="scss">
.d-widget {
  .d-icon {
    font-size: var(--icon-size-l);
    color: var(--grey-400);
    position: absolute;
    left: 8px;
    top: 8px;
  }

  .d-modulo {
    position: absolute;
    right: 16px;
    top: 8px;
  }

  .d-widget-body {
    .body {
      margin: auto;
      width: 100%;
    }
  }
}

.d-display-sm-semibold {
  font-size: 2.14286em;
  line-height: 1.26666em;
}</style>
