<script setup lang="ts">
import DWidget from 'src/components/DWidget.vue'
import DNewsArticle from 'src/components/DNewsArticle.vue'
import { useNews } from 'src/composables/news'
import { ref } from 'vue'

const props = defineProps<{ mode: 'horizontal' | 'vertical'; height?: number }>()

const autoplay = ref(20000)
const slideIndex = ref(1)
const { articles, loading } = useNews()

console.log('DWidgetNews')
</script>

<template>
  <d-widget
    class="d-widget-news"
    border-header
    :loading="loading"
  >
    <template
      v-if="mode == 'horizontal' || (height ?? 0) > 1"
      v-slot:header
    >
      <d-icon name="newspaper" />
      <div class="d-display-xs-semibold">
        {{ $t('Noticias') }}
      </div>
    </template>
    <template v-slot:body>
      <template v-if="props.mode == 'vertical'">
        <div
          class="d-widget-news-mini"
          v-if="height == 1"
        >
          <q-carousel
            class="d-widget-news-mini-carousel"
            v-model="slideIndex"
            animated
            swipeable
            infinite
            transition-prev="slide-right"
            transition-next="slide-left"
            :autoplay="autoplay"
            @mouseenter="autoplay = 0"
            @mouseleave="autoplay = 20000"
          >
            <q-carousel-slide
              v-for="(article, index) in articles"
              :key="index"
              :name="index"
            >
              <d-news-article
                text-overlap
                :article="article"
              ></d-news-article>
            </q-carousel-slide>
          </q-carousel>
          <d-icon name="newspaper" />
        </div>
        <div
          v-else
          class="d-widget-news-vertical"
        >
          <d-news-article
            v-for="(article, index) in articles"
            :key="index"
            image-rounded
            :article="article"
            class="q-ml-md q-mr-md q-mb-md"
          ></d-news-article>
        </div>
      </template>
      <template v-else>
        <div class="d-widget-news-horizontal">
          <div
            class="d-widget-news-horizontal-article q-mr-md"
            v-for="(article, index) in articles"
            :key="index"
          >
            <d-news-article
              text-overlap
              image-rounded
              :article="article"
            ></d-news-article>
          </div>
        </div>
      </template>
    </template>
  </d-widget>
</template>

<style scoped lang="scss">
.d-widget {
  .d-widget-news-mini {
    .d-icon {
      font-size: var(--icon-size-l);
      color: var(--grey-25);
      position: absolute;
      top: 11px;
      left: 11px;
    }

    .d-widget-news-mini-carousel {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .d-widget-news-vertical {
    position: relative;
    display: block;
    margin-right: 8px;
    width: calc(100% - 8px);
    height: 100%;
    overflow-y: auto;

    .d-news-article {
      width: calc(100% - 32px);
    }
  }

  .d-widget-news-horizontal {
    position: relative;
    width: calc(100% - 32px);
    margin-right: 16px;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-left: 16px;

    .d-widget-news-horizontal-article {
      width: 188px;
      height: calc(100% - 16px);
      display: inline-block;
      position: relative;
    }
  }
}
</style>
