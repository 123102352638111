import { KPIsAgrupado, KPIsResponse, KPIsResponseItem } from 'src/model/response/metrics.models'
import { metricsService } from 'src/services/metricsService'
import { ref, computed } from 'vue'
import { fromYearMonth, trend } from 'driving01-charts'

const kpis = ref<KPIsResponse>()
const kpisAgrupado = ref<{ [id: string]: KPIsAgrupado }>()
const kpisPorModulo = ref<{ [modulo: string]: KPIsAgrupado[] }>()
const kpisLoading = ref(false)

export function clearKPIs() {
  kpis.value = undefined
  kpisAgrupado.value = undefined
  kpisPorModulo.value = undefined
  kpisLoading.value = false
}

export function useKPIs(baseInterval: 'year' | 'month' | 'day', baseIncrement: number) {
  if (kpis.value === undefined && !kpisLoading.value) {
    kpisLoading.value = true
    metricsService.getKPIsAgrupados().then((value) => {
      kpisAgrupado.value = value.reduce((obj, kpiAgrupado) => {
        const id = kpiAgrupado.id || ''
        obj[id] = kpiAgrupado

        return obj
      }, {} as { [id: string]: KPIsAgrupado })

      kpisPorModulo.value = {}
      for (const idKpi in kpisAgrupado.value) {
        const modulo = kpisAgrupado.value[idKpi].modulo
        if (kpisPorModulo.value[modulo] == null) {
          kpisPorModulo.value[modulo] = []
        }
        kpisPorModulo.value[modulo].push(kpisAgrupado.value[idKpi])
      }

      if (value != null) {
        kpis.value = []
        for (const kpiAgrupado of value) {
          for (const valor of kpiAgrupado.valores) {
            if (valor.pkAnyomes <= 9999) {
              kpiAgrupado.modoAño = true
              valor.pkAnyomes = valor.pkAnyomes * 100 + 1
            }
            kpis.value?.push({
              id: kpiAgrupado.id,
              modulo: kpiAgrupado.modulo,
              modoAño: kpiAgrupado.modoAño,
              tipoKPI: kpiAgrupado.tipoKPI,
              tipoTendencia: kpiAgrupado.tipoTendencia,
              indicador: kpiAgrupado.indicador,
              pkAnyomes: valor.pkAnyomes,
              valor: valor.valor,
            })
          }
        }
      }

      kpisLoading.value = false
    })
  }

  const filterKpis = (date: number) => (kpis.value === undefined ? [] : kpis.value.filter((m) => m.pkAnyomes === date))

  const toKpiDate = (d: Date) => d.getFullYear() * 100 + d.getMonth() + 1

  const toDate = (kpiDate: number) => new Date(Math.floor(kpiDate / 100), Math.floor(kpiDate % 100) - 1, 1)

  const dateAdd = (interval: 'year' | 'month' | 'day', increment: number, date: Date) => {
    if (interval === 'year') date.setFullYear(date.getFullYear() + increment)
    if (interval === 'month') date.setMonth(date.getMonth() + increment)
    if (interval === 'day') date.setDate(date.getDate() + increment)
    return date
  }

  const counterKpi = computed(() => {
    return (id: string, interval: 'year' | 'month' | 'day' = baseInterval, increment: number = baseIncrement) => {
      if (kpisAgrupado.value != null) {
        const kpi = kpisAgrupado.value[id]
        const lastValue = kpi.valores[kpi.valores.length - 1]
        if (lastValue != null && lastValue.pkAnyomes) {
          const counterDate = toKpiDate(dateAdd(interval, increment, toDate(lastValue.pkAnyomes)))
          const counterKpi = kpi.valores.find((a) => a.pkAnyomes == counterDate)
          const valoresPeriodo = kpi.valores.filter((m) => m.pkAnyomes >= counterDate)

          let trendValues = undefined

          let color = 'success'
          let colorTendencia = 'success'

          if (valoresPeriodo != null && valoresPeriodo.length > 0) {
            switch (kpi.tipoKPI) {
              case 1: //KPI Valor (NO TENDENCIA)
                if (kpi.tipoTendencia > 0) color = lastValue.valor >= 0 ? 'success' : 'error'
                else color = lastValue.valor <= 0 ? 'success' : 'error'

                break
              case 2: //KPI con tendencia
                trendValues = trend(valoresPeriodo.map((x) => ({ x: +fromYearMonth(x.pkAnyomes), y: x.valor })))
                const diferencia = lastValue.valor - (counterKpi?.valor ?? 0)
                if (diferencia == 0) color = 'warn'
                else if (kpi.tipoTendencia > 0) color = diferencia >= 0 ? 'success' : 'error'
                else color = diferencia <= 0 ? 'success' : 'error'

                const valorInicial = trendValues[0].y
                const valorFinal = trendValues[trendValues.length - 1].y
                const diferenciaTedencia = valorFinal - valorInicial
                const porcentajeDiferenciaTendencia = (diferenciaTedencia / Math.abs(valorInicial)) * 100
                if (Math.abs(porcentajeDiferenciaTendencia) < 1) colorTendencia = 'warn'
                else {
                  if (kpi.tipoTendencia > 0) colorTendencia = porcentajeDiferenciaTendencia >= 0 ? 'success' : 'error'
                  else colorTendencia = porcentajeDiferenciaTendencia <= 0 ? 'success' : 'error'
                }

                break
            }
          }
          return {
            valor: lastValue.valor,
            contraValor: counterKpi?.valor,
            id: kpi.id,
            indicador: kpi.indicador,
            tipoKPI: kpi.tipoKPI,
            tipoTendencia: kpi.tipoTendencia,
            modoAño: kpi.modoAño,
            valores: valoresPeriodo,
            color: color,
            trend: trendValues,
            trendColor: colorTendencia,
          }
        }
      }
    }
  })

  const counterKpis = computed(() => {
    const d = new Date()
    const currentDate = toKpiDate(d)

    const currentKpis = filterKpis(currentDate)
    const counterDate = toKpiDate(dateAdd(baseInterval, baseIncrement, d))

    const _counterKpis = filterKpis(counterDate)

    if (currentKpis.length === 0) return currentKpis

    return currentKpis
      .map((currentKpi) => {
        //console.log(o)
        const counter = _counterKpis.find((c: KPIsResponseItem) => currentKpi.id === c.id)
        const diferencia = (currentKpi?.valor ?? 0) - (counter?.valor ?? 0)
        let color = 'success'
        if (diferencia == 0) color = 'warn'
        else if ((currentKpi?.tipoTendencia ?? 0) > 0) color = diferencia >= 0 ? 'success' : 'error'
        else color = diferencia <= 0 ? 'success' : 'error'

        return {
          [currentKpi.id as string]: {
            ...currentKpi,
            contraValor: counter?.valor,
            porcentajeDiferencia: Math.round((diferencia / Math.abs(counter?.valor ?? 0)) * 100 * 100) / 100,
            color,
          },
        }
      })
      .reduce((a, b) => ({ ...a, ...b }))
  })

  return { kpis, kpisAgrupado, kpisPorModulo, counterKpis, counterKpi, kpisLoading }
}

export type KPI = {
  id: string
  valor: number
  contraValor: number
  indicador: string
  modulo: string
  pkAnyomes: number
}
