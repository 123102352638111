import CryptoJS from 'crypto-js'
const keySize = 256
const ivSize = 128
const saltSize = 256
const iterations = 1000
export default {
  encrypt(msg: string, pass: string): string {
    const salt = CryptoJS.lib.WordArray.random(saltSize / 8)

    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: iterations,
    })

    const iv = CryptoJS.lib.WordArray.random(ivSize / 8)

    const encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    })

    const encryptedHex = this.base64ToHex(encrypted.toString())
    const base64result = this.hexToBase64(
      salt.toString() + iv.toString() + encryptedHex
    )

    return base64result
  },
  decrypt(transitmessage: string, pass: string): string {
    const hexResult = this.base64ToHex(transitmessage)

    const salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64))
    const iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32))
    const encrypted = this.hexToBase64(hexResult.substring(96))

    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: iterations,
    })

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
  },
  hexToBase64(str: string): string {
    if (str != null) {
      const text = str
        .match(/\w{2}/g)
        ?.map((a) => {
          return String.fromCharCode(parseInt(a, 16))
        })
        .join('')

      if (text != null) return btoa(text)
      else return ''
    } else return ''
  },
  base64ToHex(str: string): string {
    const hex: string[] = []
    for (
      let i = 0, bin = atob(str.replace(/[ \r\n]+$/, ''));
      i < bin.length;
      ++i
    ) {
      let tmp = bin.charCodeAt(i).toString(16)
      if (tmp.length === 1) tmp = '0' + tmp
      hex[hex.length] = tmp
    }
    return hex.join('')
  },
}
